import apiClient from "../../ApiClient";


export const SET_USER = 'SET_USER';

export const getUserList = (request) => async (dispatch) => {
    try {
      const response = await apiClient.post(`/user/list`, request);
      return {status:true, data: response.data.data};
    } catch (error) {
      return {status:false, error: error.response};
    }
};

export const getCurrentUser = () => async (dispatch) => {
  try {
    const response = await apiClient.get(`/user/current`);
    dispatch({ type: SET_USER, payload: response.data });
    return {status:true, data: response.data};
  } catch (error) {
    return {status:false, error: error.response};
  }
};

export const deactivateUser = (request) => async (dispatch) => {
  try {
    const response = await apiClient.put(`/user/deactivate`, request);
    return {status:true, data: response.data};
  } catch (error) {
    return {status:false, error: error.response};
  }
};

export const activateUser = (request) => async (dispatch) => {
  try {
    const response = await apiClient.put(`/user/activate`, request);
    return { status:true, data: response.data };
  } catch (error) {
    return {status:false, error: error.response};
  }
};

export const getRoles = () => async (dispatch) => {
  try {
    const response = await apiClient.get(`/user/roles`);
    return {status:true, data: response.data};
  } catch (error) {
    return {status:false, error: error};
  }
};

export const inviteUser = (request) => async (dispatch) => {
  try {
    const response = await apiClient.post(`/user/invite-user`, request);
    return {status:true, data: response.data};
  } catch (error) {
    return {status:false, error: error};
  }
}