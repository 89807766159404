import React, { useEffect, useState } from "react";
import { Empty, Flex } from 'antd';
import { analyticalData } from "../../redux/actions/SchedulerRunActions";
import StackedChartComponent from "./charts/StackedBarComponent";  
import BarChartComponent from "./charts/BarComponent";
import PieChartComponent from "./charts/PieComponent";
import TableViewComponent from "./charts/TableViewComponent";

function ChartComponent(props) {
    const { properties, currentRunId } = props;
    const [chartData, setChartData] = useState(null);

    useEffect(() => {
        async function getAnalyticData() {
            const response = await analyticalData(currentRunId, properties.ReportName);
            if (response.status) {
                setChartData(response.data)
            }
        }
        getAnalyticData();
    }, [currentRunId, properties.ReportName])

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        });
    };

    return (
        chartData && chartData.length > 0 ? (
          <>
            {(() => {
              switch (properties.chartType) {
                case "stacked":
                  return <StackedChartComponent data={chartData} properties={properties} formatDate={formatDate} />;
                case "bar":
                  return <BarChartComponent data={chartData} properties={properties} formatDate={formatDate} />;
                case "pie":
                  return <PieChartComponent data={chartData} properties={properties} />;
                case "table":
                  return <TableViewComponent data={chartData} properties={properties} />;
                // Add more cases here if needed
                default:
                  return <div>No chart type selected</div>;
              }
            })()}
          </>
        ) : <Flex align="center" justify="center" className="empty-data-placeholder"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></Flex>
      );

}

export default ChartComponent;