import { configureStore, combineReducers } from '@reduxjs/toolkit';
import {thunk} from 'redux-thunk';
import SiteReducer from './reducers/SiteReducer';
import SchedulerRunReducer from './reducers/SchedulerRunReducer';
import UserReducer from './reducers/UserReducer';

const appReducer = combineReducers({
  site: SiteReducer,
  schedulerRun: SchedulerRunReducer,
  user: UserReducer 
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    state = undefined;
  }
  return appReducer(state, action);
};

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});

export default store;