import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Layout, Menu, Button, Drawer, Divider } from 'antd';
import authToken from "../../../AuthToken";
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { MenuItems } from "./MenuItems";
import './sidenav.scss';
import { App } from 'antd';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import useLanguageChange from "../../../hooks/useLanguageChange"; // Import the custom hook
import { useDispatch } from 'react-redux'; // Import useDispatch from react-redux

const { Sider } = Layout;

function SideNav(props) {
    const { disabled, currentSite } = props;
    const [openKeys, setOpenKeys] = useState(["dashboard"]);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const navigate = useNavigate();
    const { message } = App.useApp();
    const { t, i18n } = useTranslation('sidenav'); // Initialize useTranslation with 'sidenav' namespace and get i18n instance
    const dispatch = useDispatch(); // Initialize useDispatch

    useLanguageChange(() => {
        // Force re-render on language change
        setOpenKeys([...openKeys]);
    });

    const menuItems = MenuItems({ handleSignOut, disabled, basePath: currentSite?.siteCode });

    function handleSubmenuOpen(keys) {
        const prevOpenSubMenu = keys.slice(-1);
        setOpenKeys(prevOpenSubMenu ? [...prevOpenSubMenu] : []);
    };

    function toggleDrawer() {
        setDrawerVisible(!drawerVisible);
    };

    function handleSignOut() {
        message.success(t('logout_success')); // Use translation
        authToken.removeItem('access-token');
        authToken.removeItem('site-code');
        dispatch({ type: 'USER_LOGOUT' }); // Dispatch the USER_LOGOUT action
        navigate('/login');
    }

    return (
        <>
            <Sider
                className="sider"
                theme="light"
                collapsible
                collapsed={collapsed}
                trigger={null}
                width={208}
            >
                <Menu
                    mode="inline"
                    theme="light"
                    className="main-menu"
                    defaultSelectedKeys={['analysis']}
                    openKeys={openKeys}
                    onOpenChange={handleSubmenuOpen}
                    items={menuItems} // Use translated menu items
                >
                </Menu>
                <Divider className="item-divider" />
                <Button
                    type="text"
                    icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                    onClick={() => setCollapsed(!collapsed)}
                    className="collapse-btn"
                />
            </Sider>
                <Button
                    className="toggle-button"
                    onClick={toggleDrawer}
                    icon={drawerVisible ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
                />
                <Drawer
                    placement="left"
                    closable={false}
                    onClose={toggleDrawer}
                    open={drawerVisible}
                    width={200}
                    className="menu-drawer"
                >
                    <Menu
                        className="main-menu"
                        mode="inline"
                        theme="light"
                        defaultSelectedKeys={["analysis"]}
                        items={menuItems} // Use translated menu items
                    />
                </Drawer>
        </>
    )
}

export default SideNav;
