import React from "react";
import tableMappings from "../../scheduler/scheduler_table_settings.json";
import {Table} from 'antd';

function RunDetailTableView(props) {
    const {runDetails,runDetailKey} = props;
    const runDetailFieldMappings = tableMappings.run_details.find(param => param.name === runDetailKey).properties;
    const columns = runDetailFieldMappings.filter(field => field.visibility === "Show").map(field => (
        {
            title: field.label || field.name,
            dataIndex: field.name,
            key: field.name
        }
    ))

    const runDetailFields = Object.keys(runDetails);
    const length = runDetails[runDetailFields[0]]?.length;
    const data = Array.from({ length }, (_, index) => {
        const currentRow = { key: index };
        runDetailFields.forEach((field) => {
            currentRow[field] = runDetails[field][index];
        });
        
        return currentRow;
    });
    
    return (
        <Table
          columns={columns}
          bordered
          dataSource={data}
          pagination={false}
          className="detail-view"
        />
    );
}

export default RunDetailTableView;