import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Navigate } from 'react-router-dom';
import authToken from './AuthToken';
import { message } from 'antd';
import { getCurrentUser } from './redux/actions/UserAction';

function AuthGuard({ children }) {
  const isAuthenticated = authToken.getItem('access-token');
  const user = useSelector(state => state.user.user);
  const dispatch = useDispatch();

  useEffect(() => {
      if (isAuthenticated && !user) {
          dispatch(getCurrentUser());
      }
  }, [isAuthenticated, user, dispatch]);

  useEffect(() => {
    if (!isAuthenticated) {
      message.error("You have been signed out kindly login again");
    }
  }, [isAuthenticated]);

  if (!isAuthenticated) {
    return <Navigate to='/login' />;
  }
  else {
    return children;
  }
};

export default AuthGuard;
