import React,{useState,useEffect} from "react";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import {Card,Flex,Tabs,message,Button,Tooltip,Input,Modal} from 'antd';
import { CopyOutlined, SaveOutlined, EditTwoTone, FileAddOutlined, BulbOutlined, DeleteOutlined, MoreOutlined } from "@ant-design/icons";
import InputTables from './InputTables';
import { executeRun, getRunData, saveRunData, deleteRun } from "../../redux/actions/SchedulerRunActions";
import OutputTables from "./OutputTables";
import '../../style.scss';
import './rundetails.scss';
import { handleRunClone } from "./SchedulerService";
import GanttChart from "./GanttChart";
import DashboardLayout from "../dashboard/DashboardLayout";
import dashboardSettings from '../dashboard/dashboard_settings.json';
import RunDetailGridView from "./rundetailtabs/RunDetailGridView";

function RunDetails() {
    const {siteCode,runId} = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [tabItems,setTabItems] = useState([]); // holds tab items list
    const [runData,setRunData] = useState(null); // holds run details
    const [activeTab, setActiveTab] = useState('summary'); // holds active tab key
    const [loader,setLoader] = useState(false); // holds the loader status for save button
    const [runName,setRunName] = useState(''); // holds the edited run name
    const [isEditing, setIsEditing] = useState(false); // holds editing state of run name field
    const [showConfirmation, setShowConfirmation] = useState(false);

    async function handleSaveDetails() {
        setLoader(true);
        const { output_tables, ...runDetailFields } = runData;
        const response = await dispatch(saveRunData(runDetailFields))
        if(response.status) {
            message.success('Run Details Saved Successfully');
        }
        else {
            message.error('Something went wrong');
        }
        setLoader(false);
    }
    
    useEffect(() => {
        async function getRunDetail() {
            const response = await dispatch(getRunData(runId));

            if(response.status) {
                setRunData(response.data);
                setTabItems([
                    {
                        label: 'Summary',
                        key: 'summary',
                        children: <DashboardLayout enableSave={true} dashboardSettings={dashboardSettings} data={response.data} currentRunId={runId}/>
                    },
                    {
                        label: 'Schedule',
                        key: 'gantt',
                        children: <GanttChart data={response.data}/>
                    },
                    {
                        label: 'Input',
                        key: 'input',
                        children: <InputTables tables={response.data.input_tables}/>
                    },
                    {
                        label: 'Parameters',
                        key: 'parameters',
                        children: <RunDetailGridView allowEditing={true} currentRunDetail={response.data.run_parameter_list} runDetailKey="run_parameter_list"/>
                    },
                    {
                        label: 'Output',
                        key: 'output',
                        children: <OutputTables tables={response.data.output_tables}/>
                    }
                ])
            }
        }

        getRunDetail();
    },[dispatch,runId])

    async function handleRunSubmit() {
        const runParams = {'run_id': parseInt(runId)};
        const response = await dispatch(executeRun(runParams));
        if(response.status) {
            message.success('Run Submitted Successfully');
        }
        else {
            message.error('Something went wrong');
        }
    }

    function handleEditRunClick() {
        setIsEditing(true);
        setRunName(runData.run_name ? runData.run_name : runData.run_id)
    };

    function handleInputChange(e) {
        setRunName(e.target.value);
    };

    function handleSaveRunName() {
        setIsEditing(false);
        setRunData({...runData, run_name: runName})
    }

    async function onDeleteRun(runId) {
        const response = await dispatch(deleteRun([runId]))
        setShowConfirmation(false);
        if(response.status) {
            message.success('Run Deleted Successfully');
            navigate(`/${siteCode}/scheduler/runs`);
        }
        else {
            message.error('Something went wrong');
        }
    }

    async function onDuplicateRun() {
        const response = await handleRunClone(dispatch,parseInt(runId));
        if(response.status) {
            message.success('Run duplicated Successfully');
            navigate(`/${siteCode}/scheduler/runs`);
        }
        else {
            message.error('Something went wrong');
        }
    }

    const enableSave = activeTab === 'input' || activeTab === 'parameters';

    return (
        runData &&
        (<Card
            className="card-info run-detail-card"
            title={
                <Flex gap={13} align="center">
                    <span>Run Name: </span>
                    {isEditing ? (
                        <Input
                            value={runName}
                            onChange={handleInputChange}
                            onBlur={handleSaveRunName}
                            onPressEnter={handleSaveRunName}
                            className="run-name"
                            autoFocus
                        />
                    ) : (
                        `${runData && (runData.run_name ? runData.run_name : runData.run_id)}`
                    )}
                    <Tooltip title="Edit Name"><EditTwoTone onClick={handleEditRunClick}/></Tooltip>
                </Flex>
            }
            extra={
                <Flex gap={16}>
                    <Flex gap={8}>
                        <Button icon={<CopyOutlined/>}>Publish</Button>
                        <Button icon={<FileAddOutlined/>} onClick={onDuplicateRun}>Clone</Button>
                        <Button icon={<BulbOutlined/>} onClick={handleRunSubmit}>Execute</Button>
                        <Button icon={<DeleteOutlined/>} onClick={() => setShowConfirmation(true)}>Delete</Button>
                    </Flex>
                    <Tooltip title="More Actions"><MoreOutlined className="action-icon" /></Tooltip>
                </Flex>
            }
            actions={enableSave && [
                
                (<Flex className="save-btn-container" justify="end">
                    <Button icon={<SaveOutlined />} loading={loader} type="primary" className="save-btn" onClick={handleSaveDetails}>
                        Save All
                    </Button>
                </Flex>)
            ]}
        >
            {tabItems.length > 0 &&
                <Tabs
                    className="run-detail-tabs"
                    defaultActiveKey="summary"
                    type="card"
                    size="small"
                    items={tabItems}
                    onChange={(key) => setActiveTab(key)}
                />
            }
            <Modal
                title="Delete Confirmation"
                open={showConfirmation}
                onOk={() => onDeleteRun(parseInt(runData.run_id))}
                onCancel={() => setShowConfirmation(false)}
                okText="Yes, Delete"
                cancelText="Cancel"
                okType="danger"
            >
                <p>Are you sure want to delete the run <b><i>{runData.run_name}</i></b> ?</p>
            </Modal>
        </Card>)
    )
}

export default RunDetails;