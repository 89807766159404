import { Link } from 'react-router-dom';
import {
  SettingOutlined,
  DashboardOutlined,
  PieChartOutlined,
  ScheduleOutlined,
  CodepenOutlined,
  ShoppingCartOutlined,
  MessageOutlined,
  UserOutlined,
  LogoutOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next'; // Import useTranslation

export function MenuItems(props) {
  const { handleSignOut, disabled, basePath } = props;
  const { t } = useTranslation('sidenav'); // Initialize useTranslation with 'sidenav' namespace

  const menuItems = [
    {
      key: "dashboard",
      icon: <DashboardOutlined />,
      label: t('menu.dashboard.label'),
      children: [
        {
          key: "analysis",
          label: <Link to="/">{t('menu.dashboard.analysis')}</Link>,
        },
        {
          key: "monitor",
          label: <Link to="/">{t('menu.dashboard.monitor')}</Link>,
        },
        {
          key: "workplace",
          label: <Link to="/">{t('menu.dashboard.workplace')}</Link>,
        },
      ],
    },
    {
      key: "resources",
      icon: <CodepenOutlined />,
      label: t('menu.resources.label'),
      children: [
        { key: "workstations", label: <Link to="/">{t('menu.resources.workstations')}</Link> },
        { key: "tools", label: <Link to="/">{t('menu.resources.tools')}</Link> },
        {
          key: "personnel-registry",
          label: <Link to="/">{t('menu.resources.personnel-registry')}</Link>,
        },
        { key: "customers", label: <Link to="/">{t('menu.resources.customers')}</Link> },
        { key: "shift-scheduling", label: <Link to="/">{t('menu.resources.shift-scheduling')}</Link> },
        {
          key: "personnel-scheduling",
          label: <Link to="/">{t('menu.resources.personnel-scheduling')}</Link>,
        },
        { key: "default-shifts", label: <Link to="/">{t('menu.resources.default-shifts')}</Link> },
      ],
    },
    {
      key: "orders",
      icon: <ShoppingCartOutlined />,
      label: t('menu.orders.label'),
      children: [
        { key: "all-orders", label: <Link to="/">{t('menu.orders.all-orders')}</Link> },
        { key: "order-items", label: <Link to="/">{t('menu.orders.order-items')}</Link> },
        { key: "assembly-jobs", label: <Link to="/">{t('menu.orders.assembly-jobs')}</Link> },
        { key: "process-steps", label: <Link to="/">{t('menu.orders.process-steps')}</Link> },
      ],
    },
    {
      key: "scheduling",
      icon: <ScheduleOutlined />,
      label: t('menu.scheduling.label'),
      children: [
        { key: "find-runs", label: <Link to={`/${basePath}/scheduler/runs`}>{t('menu.scheduling.find-runs')}</Link> },
        { key: "new-run", label: <Link to="/">{t('menu.scheduling.new-run')}</Link> },
        { key: "compare-runs", label: <Link to={`/${basePath}/scheduler/comparerun`}>{t('menu.scheduling.compare-runs')}</Link> },
      ],
    },
    {
      key: "reporting",
      icon: <PieChartOutlined />,
      label: t('menu.reporting.label'),
      children: [
        { key: "reports", label: <Link to="/">{t('menu.reporting.reports')}</Link> },
        { key: "create-report", label: <Link to="/">{t('menu.reporting.create-report')}</Link> },
        {
          key: "reports-history",
          label: <Link to="/">{t('menu.reporting.reports-history')}</Link>,
        },
      ],
    },
    {
      key: "settings",
      icon: <SettingOutlined />,
      label: t('menu.settings.label'),
      children: [
        { key: "site-details", label: <Link to={`/${basePath}/settings/sitedetails`}>{t('menu.settings.site-details')}</Link> },
        { key: "users", label: <Link to={`/${basePath}/settings/users`}>{t('menu.settings.users')}</Link> },
        { key: "teams", label: t('menu.settings.teams') },
      ],
    },
    {
      key: "messages",
      icon: <MessageOutlined />,
      label: t('menu.messages.label'),
      children: [],
    },
    {
      key: "account",
      icon: <UserOutlined />,
      label: t('menu.account.label'),
      children: [
        {
          key: "logout",
          icon: <LogoutOutlined />,
          label: t('menu.account.logout'),
          className: "log-out",
          onClick: handleSignOut
        }
      ],
    }
  ];

  return disabled ? menuItems : [
    {
      key: "logout",
      icon: <LogoutOutlined />,
      label: t('menu.account.logout'),
      className: "log-out",
      onClick: handleSignOut
    }
  ];
}