import {produce} from 'immer';
import {FETCH_RUN_REQUEST, FETCH_RUN_SUCCESS, FETCH_RUN_FAILURE, RUN_NOTIFICATION_RECEIVED , RUN_NOTIFICATION_COMPLETE, FETCH_RUN_BY_ID_REQUEST, FETCH_RUN_BY_ID_SUCCESS} from '../actions/SchedulerRunActions';

const initialState = {
    runs:[],
    updateRun: false,
    run_id: null
}

const SchedulerRunReducer = (state=initialState, action) => {
    return produce(state, draft => {
        switch(action.type) {
            case FETCH_RUN_REQUEST:
            case FETCH_RUN_BY_ID_REQUEST:
                break;
            case FETCH_RUN_BY_ID_SUCCESS:
                const runIndex = draft.runs.findIndex(x => x.run_id === action.payload.run_id);
                if (runIndex > -1) {
                    Object.assign(draft.runs[runIndex], action.payload);
                }
                 break;
            case FETCH_RUN_SUCCESS:
                draft.runs = action.payload
                break;
            case FETCH_RUN_FAILURE:
                break;
            case RUN_NOTIFICATION_RECEIVED:
                draft.run_id = action.payload.run_id;
                draft.updateRun = true;
                break;
            case RUN_NOTIFICATION_COMPLETE:
                draft.run_id = null;
                draft.updateRun = false;
                break;
            default:
                break;
        }
    })
}

export default SchedulerRunReducer;