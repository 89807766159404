import React, { useEffect, useState } from 'react';
import { Layout, Typography, List, Flex, Button } from 'antd'; // Import Button component
import { useSelector, useDispatch } from 'react-redux';
import { RightOutlined, PlusOutlined } from '@ant-design/icons'; // Import PlusOutlined icon
import { ReactComponent as HeaderIcon } from '../../assets/icons/AbeCollective.svg';
import { getSites, SET_CURRENT_SITE, TOGGLE_CREATE_SITE_FLOW } from '../../redux/actions/SiteActions'; // Corrected import path
import { useNavigate } from 'react-router-dom'; // Import useNavigate

import './choose-site.scss';

const { Header, Content } = Layout;
const { Title } = Typography;

const ChooseSiteComponent = () => {
    //const sites = useSelector((state) => state.site.sites);

    const [sites, setSites] = useState([]);
    const user = useSelector((state) => state.user.user);
    const dispatch = useDispatch();
    const navigate = useNavigate(); // Initialize useNavigate

    const chooseSiteHandle = (site) => {
        dispatch({ type: SET_CURRENT_SITE, payload: site });
        localStorage.setItem('site-code', site.siteCode); // Set siteCode in localStorage
        navigate('/'); // Navigate to root path
    };

    const navigateToSettings = () => {
        dispatch({ type: TOGGLE_CREATE_SITE_FLOW, payload: true });
        navigate('/settings'); // Navigate to /settings
    };

    const loadLogo = (logo) => {
        return (
            <Flex className="site-image" justify="center" align="center">
                <img className="company-logo" src={`data:image/png;base64,${logo}`} alt="company-logo" />
            </Flex>
        );
    }

    const loadTitle = (siteName) => {
        return (
            <Flex className="site-image" justify="start" align="center">
                {siteName}
            </Flex>
        );
    }

    useEffect(() => {
        const fetchSites = async () => {
            const response = await dispatch(getSites());
            if (response.status) {
                setSites(response.data);
            }
        };
        fetchSites();
    }, []); // Empty dependency array to run only once

    return (
        user && sites && sites.length > 0 &&
        <div className="choose-site-container">
            <div className="choose-site-content">
                <Flex className='header' align='center' justify="center">
                    <HeaderIcon className='header-icon' />
                    <h1>AbeCollective</h1>
                </Flex>
                <Header>
                    <Title level={4}>Welcome back, {user.fullName}</Title>
                </Header>
                <Content className='choose-site-list'>
                    <List className='site-list ant-table-body'
                        itemLayout="horizontal"
                        dataSource={sites}
                        renderItem={site => (
                            <List.Item
                                className="site-list-item hover-depth-effect"
                                onClick={() => chooseSiteHandle(site)} // Add onClick handler
                            >
                                <List.Item.Meta
                                    className='site-list-item-meta'
                                    title={loadTitle(site.companyName)}
                                    description={loadTitle(`${site.siteName} - ${site.siteCode}`)}
                                    avatar={loadLogo(site.companyLogo)}
                                    content={loadLogo(site.companyLogo)}
                                />
                                <div className="icon-container">
                                    <RightOutlined className="hover-icon" />
                                </div>
                            </List.Item>
                        )}
                    />
                </Content>
                <Flex justify="center" style={{ marginTop: '20px' }}>
                    <Button type="primary" icon={<PlusOutlined />} onClick={navigateToSettings}>Create Site</Button>
                </Flex>
            </div>
        </div>
    );
};

export default ChooseSiteComponent;
