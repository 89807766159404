import React from "react";
import { InfoCircleOutlined, CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { Tooltip, Flex } from 'antd';

function PasswordValidator(props) {
    const {criteria} = props;

    return (
        <Tooltip
            placement='bottom'
            color="white"
            overlayInnerStyle={{ color: 'black' }}
            title={
                <div className="custom-tooltip">
                    <Flex align="center" className='tooltip-criteria'>
                        {criteria.length ? <CheckCircleFilled className="success" /> : <CloseCircleFilled className="error" />}
                        <p className='check-msg'>At least 8 characters</p>
                    </Flex>
                    <Flex align="center" className='tooltip-criteria'>
                        {criteria.lowercase ? <CheckCircleFilled className="success" /> : <CloseCircleFilled className="error" />}
                        <p className='check-msg'>At least one lowercase letter</p>
                    </Flex>
                    <Flex align="center" className='tooltip-criteria'>
                        {criteria.uppercase ? <CheckCircleFilled className="success" /> : <CloseCircleFilled className="error" />}
                        <p className='check-msg'>At least one uppercase letter</p>
                    </Flex>
                    <Flex align="center" className='tooltip-criteria'>
                        {criteria.number ? <CheckCircleFilled className="success" /> : <CloseCircleFilled className="error" />}
                        <p className='check-msg'>At least one number</p>
                    </Flex>
                    <Flex align="center" className='tooltip-criteria'>
                        {criteria.specialChar ? <CheckCircleFilled className="success" /> : <CloseCircleFilled className="error" />}
                        <p className='check-msg'>At least one special character</p>
                    </Flex>
                </div>
            }
        >
            <InfoCircleOutlined />
        </Tooltip>
    )
}

export default PasswordValidator;