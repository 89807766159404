import React, {useState,useEffect} from "react";
import { useDispatch } from "react-redux";
import { getSchedulerRuns, getRunData, compareRun } from "../../redux/actions/SchedulerRunActions";
import {Flex, Select, Divider, Tree, Table, Spin, Button, message} from 'antd';
import { DownOutlined } from "@ant-design/icons";
import './compare-run.scss';
import tableMappings from "./scheduler_table_settings.json";
import compareRunSettings from './compare_run_settings.json';
import GridLayout from "../gridlayout/GridLayout";

function CompareRun() {
    const [runsList, setRunsList] = useState([]);
    const [selectedBaseRun, setSelectedBaseRun] = useState(null);
    const [selectedCompareRun, setSelectedCompareRun] = useState(null);
    const [runNameList, setRunNameList] = useState(null);
    const [selectedBaseRunData, setSelectedBaseRunData] = useState(null);
    const [selectedCompareRunData, setSelectedCompareRunData] = useState(null);
    const [baseRunFormattedData,setBaseRunFormattedData] = useState(null);
    const [compareRunFormattedData,setCompareRunFormattedData] = useState(null);
    const [baseRunLoader, setBaseRunLoader] = useState(false);
    const [compareRunLoader, setCompareRunLoader] = useState(false);
    const [runListLoader, setRunListLoader] = useState(true);
    const [baseRunExpandedItems, setBaseRunExpandedItems] = useState([]);
    const [compareRunExpandedItems, setCompareRunExpandedItems] = useState([]);
    const [layouts, setLayouts] = useState([]);
    const [settings, setSettings] = useState([]);
    const dispatch = useDispatch();
    const detailsObj = {'input_tables': 'Input Tables', 'run_parameter_list': 'Parameters', 'output_tables': 'Output Tables'}

    async function fetchSchedulerRuns() {
        setRunListLoader(true);
        const response = await dispatch(getSchedulerRuns());
        if (response.status && response.data.length > 0) {
            setRunsList(response.data);
            const runLabels = response.data.map(run => ({"label": run.run_name, "value": run.run_id}));
            setRunNameList(runLabels);
            setRunListLoader(false);
        }
    }

    useEffect(() => {
        fetchSchedulerRuns();
    },[dispatch])

    useEffect(() => {
        setLayouts(compareRunSettings);
        setSettings(compareRunSettings);
    },[])

    async function getRunDetail(runId) {
        const response = await dispatch(getRunData(runId));
        return response;
    }

    function handleExpandItems(expandedItems) {
        setBaseRunExpandedItems(expandedItems);
        setCompareRunExpandedItems(expandedItems);
    }

    function generateTableView(currentTable, tableType) {
        return Object.keys(currentTable).map((key, index) => {
            const currentTableMappings = tableMappings[tableType];
            const value = currentTable[key];
            if (Array.isArray(value)) {
                const columns = value.length > 0 ? currentTableMappings[key].fields
                .filter(field => field.visibility === "Show")
                .map(field => ({
                    title: field.label || field.name,
                    dataIndex: field.name,
                    key: field.name,
                })) : [];

                return {
                    title: currentTableMappings[key].label,
                    key: key,
                    children: [
                        {
                            title: (
                                <Table
                                    dataSource={value}
                                    columns={columns}
                                    pagination={false}
                                    size="small"
                                    rowKey="_id"
                                    scroll={{ x: 'max-content' }}
                                    className="data-table"
                                />
                            ),
                            key: `${tableType}/${key}`,
                            className:"data-tree"
                        },
                    ],
                };
            }

            return {
                title: `${key}: ${value}`,
                key: `${tableType}/${key}`,
            };
        });
    };

    function generateTableData(data,detailsObj) {
        const tableData = Object.keys(detailsObj).map((tableType, index) => {
            const children = generateTableView(data[tableType], tableType);
            return {
              title: detailsObj[tableType],
              key: tableType,
              children,
            };
        });

        return tableData;        
    }

    async function handleSelectBaseRun(value) {
        setSelectedBaseRun(value);
        setBaseRunLoader(true);
        const response = await getRunDetail(value);
        if(response.status) {
            setSelectedBaseRunData(response.data)
            setBaseRunFormattedData(generateTableData(response.data,detailsObj))
            setBaseRunLoader(false);
        }
    }

    async function handleSelectCompareRun(value) {
        setSelectedCompareRun(value);
        setCompareRunLoader(true);
        const response = await getRunDetail(value);
        if(response.status) {
            setSelectedCompareRunData(response.data)
            setCompareRunFormattedData(generateTableData(response.data,detailsObj))
            setCompareRunLoader(false);
        }
    }

    function formatDate(dateString) {
        const date = new Date(dateString);
        return date.toLocaleString('en-US', { 
            year: 'numeric', 
            month: 'short',
            day: 'numeric' 
        });
    }

    function handleLayoutChange(newLayout) {
        setLayouts(newLayout);
        const newSettings = settings.map(item => {
            let layout = newLayout.find(x => x.i === item.i);
            if (layout) {
              Object.assign(item, layout);
            }
            return item;
        });
        setSettings(newSettings);
    }

    async function handleFindDiff() {
        const response = await compareRun(selectedBaseRun,selectedCompareRun);
        if(response.status) {
            message.success("Run submitted and started");
        }
        else {
            message.error('Something went wrong');
        }
    }


    return (
        runsList.length > 0 ?
        (
            <Flex className="diff-view" align="center" vertical>
                <Button className="find-diff-btn" type="primary" onClick={handleFindDiff}>Analyze Differences</Button>
                <Flex className="compare-run-container">
                    <Flex className="run-container" vertical>
                        <Flex>
                            <Select
                                placeholder="Select a run"
                                value={selectedBaseRun}
                                onChange={handleSelectBaseRun}
                                options={runNameList}
                                className="run-select"
                            />
                            {selectedBaseRunData && (<div className="timeline-card detail-margin" size="small">{formatDate(selectedBaseRunData.horizon_start_time)} - {formatDate(selectedBaseRunData.horizon_end_time)}</div>)}
                        </Flex>
                        {selectedBaseRunData ? (
                            <>
                                <GridLayout setLayouts={setLayouts} setSettings={setSettings} layouts={layouts} handleLayoutChange={handleLayoutChange} settings={settings} data={selectedBaseRunData} currentRunId={selectedBaseRun}/>
                                {baseRunFormattedData && (
                                    <Tree
                                        showLine
                                        switcherIcon={<DownOutlined />}
                                        treeData={baseRunFormattedData}
                                        onExpand={handleExpandItems}
                                        expandedKeys={baseRunExpandedItems}
                                        selectable={false}
                                        expandAction="click"
                                    />
                                )}
                            </>
                        ): (<Spin spinning={baseRunLoader} className="loader" size="large"/>)}
                    </Flex>
                    <Divider type="vertical" className="run-divider"/>
                    <Flex className="run-container" vertical>
                        <Flex className="run-select-container">
                            <Select
                                placeholder="Select a run"
                                value={selectedCompareRun}
                                onChange={handleSelectCompareRun}
                                options={runNameList}
                                className="run-select detail-margin"
                            />
                            {selectedCompareRunData && (<div className="timeline-card" size="small">{formatDate(selectedCompareRunData.horizon_start_time)} - {formatDate(selectedCompareRunData.horizon_end_time)}</div>)}
                        </Flex>
                        {selectedCompareRunData ? (
                            <>
                                <GridLayout setLayouts={setLayouts} setSettings={setSettings} layouts={layouts} handleLayoutChange={handleLayoutChange} settings={settings} data={selectedCompareRunData} currentRunId={selectedCompareRun}/>
                                {compareRunFormattedData && (
                                    <Tree
                                    showLine
                                    switcherIcon={<DownOutlined />}
                                    treeData={compareRunFormattedData}
                                    onExpand={handleExpandItems}
                                    expandedKeys={compareRunExpandedItems}
                                    selectable={false}
                                    />
                                )}
                            </>
                        ) : (<Spin spinning={compareRunLoader} className="loader" size="large"/>)}
                    </Flex>
                </Flex>
            </Flex>
        ) : (<Spin spinning={runListLoader} className="loader" size="large"/>)
    )
}

export default CompareRun;