export const colors = [
    '#e6f4ff',
    '#bae0ff',
    '#91caff',
    '#69b1ff',
    '#4096ff',
    '#1677ff',
    '#0958d9',
    '#003eb3',
    '#002c8c',
    '#001d66'
];

export const highlightColors = [
    '#FFA39E',
    '#FFBB96',
    '#FFD591',
    '#FFE58F',
    '#FFF566',
    '#D3F261',
    '#95DE64',
    '#5CDBD3',
    '#85A5FF',
    '#B37FEB'
]