import React, {useState} from 'react';
import './login.scss';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, Flex } from 'antd';
import logo from '../../assets/login.jpg';
import { Link } from 'react-router-dom';
import { ReactComponent as HeaderIcon } from '../../assets/icons/AbeCollective.svg';
import apiClient from '../../ApiClient';
import { useNavigate } from 'react-router-dom';
import authToken from '../../AuthToken';
import { App } from 'antd';
import { useDispatch } from 'react-redux';

export default function LoginComponent() {
    const navigate = useNavigate();
    const [loader,setLoader] = useState(false);
    const { message } = App.useApp();
    const dispatch = useDispatch();

    async function onFinish(values) {
        try {
            setLoader(true);
            const response = await apiClient.post('/auth/login', values);
            if (response.data && response.data.status === 200) {
                const { email, firstName, lastName } = response.data.data;
                dispatch({ type: 'SET_USER', payload: {email: email, fullName: `${firstName} ${lastName}`} });
                authToken.setItem("access-token",response.data.token);
                navigate("/");

            } else {
                message.error(response.data && response.data.message ? response.data.message : 'Sign up failed. Please try again.');
            }
        } catch (error) {
            message.error('Sign-in failed. Please try again.');
            console.error('Error:', error);
        } finally {
            setLoader(false);
        }
    };

    return (
        <Flex justify="space-around" className='login-container'>
            <Flex align="center" justify="end" className="left-panel">
                <img src={logo} alt="Sign in" className="sign-in-image" />
            </Flex>
            <Flex className="right-panel" vertical>
                <Flex align="center" className="form-container" vertical>
                    <Flex className='header' align='center' justify="center">
                        <HeaderIcon className='header-icon' />
                        <h1>AbeCollective</h1>
                    </Flex>
                    <p className='login-msg'>Please sign in to continue</p>
                    <Form
                        name="login"
                        className="login-form"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                    >
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    type: 'email',
                                    message: 'Please enter a valid Email!',
                                },
                                {
                                    required: true,
                                    message: 'Please enter your Email!',
                                },
                            ]}
                        >
                            <Input prefix={<UserOutlined />} placeholder="Username" />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            className='password-field'
                            rules={[
                                { required: true, message: 'Please enter the Password!' },
                            ]}
                        >
                            <Input.Password prefix={<LockOutlined />} placeholder="Password" />
                        </Form.Item>
                        <Form.Item className='forgot-link'>
                            <Flex justify="end" align="center">
                                <a href="/">Forgot password?</a>
                            </Flex>
                        </Form.Item>

                        <Form.Item className='action-btn'>
                            <Button loading={loader} block type="primary" htmlType="submit">
                                Log in
                            </Button>
                            <Flex justify='center' className='register-btn' align='center'>
                                <p className='label'>Don't have an account?</p>
                                <Link className='signup-url' to="/signup">Register</Link>
                            </Flex>
                        </Form.Item>
                    </Form>
                </Flex>
            </Flex>
        </Flex>
    );
}