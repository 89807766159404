import {produce} from 'immer';
import { SET_USER } from '../actions/UserAction';

const initialState = {
    user: null
}

const UserReducer = (state=initialState, action) => {
    return produce(state, draft => {
        switch(action.type) {
            case SET_USER:
                draft.user = action.payload;
                break;
            default:
                break;
        }
    })
}

export default UserReducer;