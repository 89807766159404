const formatTime = (date) => date.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
}).replace('am', 'AM').replace('pm', 'PM');

export function formatDate(dateValue) {

    if(dateValue && !dateValue.includes('Z')) {
        dateValue += 'Z';
    }
    
    const date = new Date(dateValue);
    const today = new Date();

    today.setHours(0, 0, 0, 0);
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    const currentYear = today.getFullYear();

    if(isNaN(date)) return 'null';

    if (date.toDateString() === today.toDateString()) {
        return `Today, ${formatTime(date)}`;
    } else if (date.toDateString() === yesterday.toDateString()) {
        return `Yesterday, ${formatTime(date)}`;    
    } else if (date.getFullYear() === currentYear) {
        return date.toLocaleDateString([], {
            day: '2-digit',
            month: 'short',
        }) + ', ' + formatTime(date);
    } else {
        return date.toLocaleDateString([], {
            day: '2-digit',
            month: 'short',
            year: '2-digit'
        }).replace(/(\d{2})$/, `’$1`) + ', ' + formatTime(date);
    }
}

export function convertDateToUtc(dateValue) {
    const date = new Date(dateValue);
    const utcDate = date.toISOString().replace('Z','');
    return utcDate;
}

export function convertDateToLocalTime(dateValue) {
    dateValue += 'Z';
    const date = new Date(dateValue);
    return date.toLocaleDateString([], {
        day: '2-digit',
        month: 'short',
        year: '2-digit'
    }) + ', ' + formatTime(date);
}