import RoutesConfig from './routes';
import { BrowserRouter as Router } from 'react-router-dom';
import "./App.scss";
import { ConfigProvider, App as AntdApp } from 'antd';

function App() {
  return (
    <div className="main">
      <div className='container'>
        <ConfigProvider
          theme={{
            token: {
              // Seed Token
              colorPrimary: '#1890FF',
              borderRadius: 2,

              // Alias Token
              colorBgContainer: '#ffff',
            },
          }}
        >
          <AntdApp>
          <Router>
            <RoutesConfig />
          </Router>
          </AntdApp>
        </ConfigProvider>
      </div>
    </div>
  );
}

export default App;
