import React, { useState, useEffect, useMemo, useRef } from "react";
import { Table, Input, Flex, Dropdown, Button } from 'antd';
import { useParams } from "react-router-dom";
import {PlusOutlined, DeleteOutlined} from "@ant-design/icons";
import './inputtables.scss';
import tableMappings from "./scheduler_table_settings.json";
import DateTimePicker from "../common/DateTimePicker";

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  isDateField,
  record,
  handleSave,
  type,
  handleDeleteRow,
  handleCloneRowAbove,
  handleCloneRowBelow,
  handleAddEmptyRow,
  ...restProps
}) => {
  const [isEditMode, setIsEditMode] = useState(false); // holds cell editable status
  const [value, setValue] = useState(record[dataIndex]); // holds the edited value of a cell
  const [cellWidth, setCellWidth] = useState(null);
  const tdRef = useRef(null);

  useEffect(() => {
    const cellValue = record[dataIndex];
    
    if (!isEditMode) {
      setValue(cellValue);
    }
  }, [record, dataIndex, isEditMode]);

  const save = () => {
    setIsEditMode(false);
    let newValue = typeof(value) !== 'string' ? value : convertDataType(value);
    // if (value && type === 'number') {
    //   newValue = Number(value);
    // } else if (value && type === 'boolean') {
    //   newValue = value === 'true';
    // } else {
    //   newValue = value;
    // }
    handleSave({ ...record, [dataIndex]: newValue });
  };

  const convertDataType = (value) => {

      // Trim the input to remove unnecessary whitespaces
      value = value.trim();

      // Check for number
      if (!isNaN(value) && value !== "") {
          return Number(value);
      }
      // Check for boolean true/false
      else if (value.toLowerCase() === 'true') {
          return true;
      }
      else if (value.toLowerCase() === 'false') {
          return false;
      }
      // Check for valid date string
      else if (!isNaN(Date.parse(value))) {
          return value.toString();
      }
  
      // Default to string if no other match
      return value;
  }

  const onInputChange = (e) => {
    setValue(e.target.value);
  };

  const handleClick = (e) => {
    e.stopPropagation();
    if (!isEditMode) {
      const width = tdRef.current?.offsetWidth;
      setCellWidth(width);
      setIsEditMode(true);
    }
  };

  const items = [
    {
      label: "Add New Row Above",
      key: "addRowAbove",
      icon: <PlusOutlined/>,
      onClick: () => {
        handleAddEmptyRow(record._id,'ABOVE');
      }
    },
    {
      label: "Clone Row Above",
      key: "cloneRowAbove",
      icon: <PlusOutlined/>,
      onClick: () => {
        handleCloneRowAbove(record._id);
      }
    },
    {
      label: "Clone Row Below",
      key: "cloneRowBelow",
      icon: <PlusOutlined/>,
      onClick: () => {
        handleCloneRowBelow(record._id);
      }
    },
    {
      label: "Add New Row Below",
      key: "addRowBelow",
      icon: <PlusOutlined/>,
      onClick: () => {
        handleAddEmptyRow(record._id,'BELOW');
      }
    },
    {
      label: "Delete Row",
      key: "deleteRow",
      icon: <DeleteOutlined/>,
      danger: true,
      onClick: () => {
        handleDeleteRow(record._id);
      },
    }
  ]

  function saveDateField(dateValue) {
    handleSave({ ...record, [dataIndex]: dateValue });
  }

  return (
    <Dropdown menu={{items}} trigger={["contextMenu"]}>
      <td {...restProps} onClick={handleClick} ref={tdRef} className="data-cell" style={{ width: cellWidth, maxWidth: cellWidth }}>
        {isDateField ? (
            <DateTimePicker dateValue={value} saveDate={saveDateField}/>
          ) : (
            isEditMode ? (
            <Input
              value={value}
              onChange={onInputChange}
              onBlur={save}
              onPressEnter={save}
              autoFocus
              style={{ width: `${cellWidth - 32}px` }}
              className="field-input"
            />
            ) : (
              <Flex align="center" className="cell-value">{children}</Flex>
            )
          )
        }
      </td>
    </Dropdown>
  );
};


function InputTables(props) {
  const {tables} = props;
  const {runId} = useParams();
  const inputTableMappings = tableMappings.input_tables
  const inputDataSource = useMemo(() =>
    Object.keys(tables).map((key) => ({ key })),
    [tables]
  );

  const [selectedInputRows, setSelectedInputRows] = useState([]);
  const [selectedTable, setSelectedTable] = useState([]);
  const [dataSource, setDataSource] = useState([]);

  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row._id === item._id);
    if (index > -1) {
      newData.splice(index, 1, { ...row });
      setDataSource(newData);
      const currentSelectedTable = selectedInputRows[0];
      tables[currentSelectedTable] = newData;
    }
  };

  useEffect(() => {
    if (inputDataSource.length > 0) {
      const defaultTableSelected = inputDataSource[0];
      setSelectedInputRows([defaultTableSelected.key]);
      setSelectedTable(tables[defaultTableSelected.key]);
      setDataSource(tables[defaultTableSelected.key]);
    }
  }, [inputDataSource, tables]);

  const onRowSelectionChange = (selectedInputRows) => {
    setSelectedInputRows(selectedInputRows);
    const selectedRow = selectedInputRows[0];
    const table = tables[selectedRow];
    setSelectedTable(table);
    setDataSource(table);
  };

  function handleDeleteRow(currentRowId) {
    const currentTableData = [...dataSource];
    const filteredRows = currentTableData.filter(row => row._id !== currentRowId);
    setDataSource(filteredRows);
    const currentSelectedTable = selectedInputRows[0];
    tables[currentSelectedTable] = filteredRows;
  }

  function createNewRow(tableLength,currentRow) {
    const newRecord = {...currentRow};
    newRecord['_id'] = String(tableLength + Math.random());
    newRecord['run_id'] = parseInt(runId);
    return newRecord;
  }

  function handleCloneRowAbove(currentRowId) {
    const currentTableData = [...dataSource];
    const currentRowIndex = currentTableData.findIndex(row => row._id === currentRowId);
    const newRecord = createNewRow(currentTableData.length,currentTableData[currentRowIndex])
    currentTableData.splice(currentRowIndex,0,newRecord);
    setDataSource(currentTableData);
    const currentSelectedTable = selectedInputRows[0];
    tables[currentSelectedTable] = currentTableData;
  }

  function handleCloneRowBelow(currentRowId) {
    const currentTableData = [...dataSource];
    const currentRowIndex = currentTableData.findIndex(row => row._id === currentRowId);
    const newRecord = createNewRow(currentTableData.length,currentTableData[currentRowIndex])
    currentTableData.splice(currentRowIndex+1,0,newRecord);
    setDataSource(currentTableData);
    const currentSelectedTable = selectedInputRows[0];
    tables[currentSelectedTable] = currentTableData;
  }

  function handleAddEmptyRow(currentRowId,position='') {
    const currentTableData = [...dataSource];
    const currentRowIndex = currentTableData.findIndex(row => row._id === currentRowId);
    const currentSelectedTable = selectedInputRows[0];
    const row = inputTableMappings[currentSelectedTable]?.fields.reduce((acc,field) => {
      acc[field.name]= ''
      return acc; 
    }, {})
    const newRow = createNewRow(currentTableData.length,row)
    position === 'ABOVE' ?  currentTableData.splice(currentRowIndex,0,newRow) : position === "BELOW" ? currentTableData.splice(currentRowIndex+1,0,newRow) : currentTableData.splice(0,0,newRow);
    setDataSource(currentTableData);
    tables[currentSelectedTable] = currentTableData;
  }

  const inputTableColumns = [
    {
      title: 'Tables',
      dataIndex: 'label',
      key: 'key',
    },
  ];

  const inputTableData = inputDataSource.map((item) => {
    const tableMap = inputTableMappings[item.key];
    return {
      key: item.key,
      label: tableMap ? tableMap.label : item.key
    };
  });

  const outputTableColumns = selectedInputRows[0] ? inputTableMappings[selectedInputRows[0]].fields
  .filter(field => field.visibility === "Show")
  .map(field => ({
      title: field.label || field.name,
      dataIndex: field.name,
      key: field.name,
      editable: true,
      isDateField: field.isDateField,
      type: typeof selectedTable[0]?.field?.name === 'boolean' ? 'boolean' : typeof selectedTable[0]?.field?.name === 'number' ? 'number' : 'string'
  })) : [];

  const mergedColumns = outputTableColumns.map((col) => {
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        isDateField: col.isDateField,
        handleSave: handleSave,
        type: col.type,
        handleDeleteRow: handleDeleteRow,
        handleCloneRowAbove: handleCloneRowAbove,
        handleCloneRowBelow: handleCloneRowBelow,
        handleAddEmptyRow: handleAddEmptyRow
      }),
    };
  });

  return (
    <Flex className="run-detail-container">
      <Table
        className="input-tables list-detail-table run-detail-table"
        columns={inputTableColumns}
        dataSource={inputTableData}
        pagination={false}
        rowSelection={{
          type: 'radio',
          onChange: onRowSelectionChange,
          selectedRowKeys: selectedInputRows,
          columnWidth: 48,
        }}
        onRow={(record) => ({
          onClick: () => {
            onRowSelectionChange([record.key]);
          },
        })}
        rowKey="key"
        scroll={{ y:'550px' }}
      />
      { (
        <Flex vertical className="table-detail-container">
          <Table
            className="detail-table run-detail-table"
            components={
              dataSource.length > 0
                ? {
                    body: {
                      cell: EditableCell,
                    },
                  }
                : undefined
            }
            dataSource={dataSource}
            columns={mergedColumns}
            pagination={false}
            rowKey="_id"
            scroll={{ x: 'max-content' }}
            tableLayout="fixed"
            locale={{
              emptyText: (
                <Flex justify="center" align="center" vertical>
                  <h3>No Data Available</h3>
                  <Button className="add-row-btn" type="primary" onClick={handleAddEmptyRow}>
                    <PlusOutlined/>
                    <span>Add New Row</span>
                  </Button>
                </Flex>
              ),
            }}
          />
        </Flex>
      )}
    </Flex>
  );
}

export default InputTables;
