import {produce} from 'immer';
import {CREATE_SITE_REQUEST, CREATE_SITE_SUCCESS, CREATE_SITE_FAILURE,
        FETCH_SITEDATA_REQUEST, FETCH_SITEDATA_SUCCESS, FETCH_SITEDATA_FAILURE,
        EDIT_SITEDATA_REQUEST, EDIT_SITEDATA_SUCCESS, EDIT_SITEDATA_FAILURE,
        SET_CURRENT_SITE, TOGGLE_CREATE_SITE_FLOW
} from '../actions/SiteActions';

const initialState = {
    sites:[],
    isFetched:false,
    currentSite: null,
    createSiteFlow: false
}

const SiteReducer = (state=initialState, action) => {
    return produce(state, draft => {
        switch(action.type) {
            case CREATE_SITE_REQUEST:
            case FETCH_SITEDATA_REQUEST:
            case EDIT_SITEDATA_REQUEST:
                break;
            case TOGGLE_CREATE_SITE_FLOW: // New case to toggle createSiteFlow
                draft.createSiteFlow = action.payload;
                draft.currentSite = null;
                break;
            case CREATE_SITE_SUCCESS:
                draft.sites.push(action.payload)
                break;
            case EDIT_SITEDATA_SUCCESS:
                const siteIndex = draft.sites.findIndex(site => site.id === action.payload.id);
                draft.sites[siteIndex] = action.payload;
                break;
            case FETCH_SITEDATA_SUCCESS:
                draft.sites = action.payload
                draft.isFetched = true;
                break;
            case CREATE_SITE_FAILURE:
            case EDIT_SITEDATA_FAILURE:
            case FETCH_SITEDATA_FAILURE:
                break;
            case SET_CURRENT_SITE:
                draft.currentSite = action.payload;
                break;
            default:
                break;
        }
    })
}

export default SiteReducer;