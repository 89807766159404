import React, { useEffect, useState } from 'react';
import * as signalR from '@microsoft/signalr';
import { notification } from 'antd';
import authToken from "../../AuthToken";
import { RUN_NOTIFICATION_RECEIVED } from "../../redux/actions/SchedulerRunActions";
import { useDispatch } from 'react-redux';
import { apiURL } from '../../ApiClient';

 
function NotificationComponent() {

    const [api, contextHolder] = notification.useNotification();
    const token = authToken.getItem('access-token');
    const dispatch = useDispatch();
    
    function handleNotification(notification) {

        if(typeof(notification) === "object") {
            if(notification.type === 1) {
                let content = notification.content;
                dispatch({ type: RUN_NOTIFICATION_RECEIVED, payload: {run_id : content.run_id } });
                api.open({
                    message: `Run Update`,
                    description: content.message,
                    placement: 'topRight',
                });
            }
        }
        else {
            api.open({
                message: `Run Update`,
                description: notification,
                placement: 'topRight',
            });
        }
    }

    useEffect(() => {
        // Setup SignalR connection
        const connection = new signalR.HubConnectionBuilder()
        .withUrl(`${apiURL}/notification-hub`, { withCredentials: true ,headers: {Authorization:  `Bearer ${token}`}}) // Use your hub URL here
        .withAutomaticReconnect()
        .build();

        // Start the connection
        connection.start()
            .then(() => {console.log("Connected to SignalR Hub"); api.open({
                message: "Connected",
                placement: 'topRight'
            }) })
            .catch(err => console.error("SignalR Connection Error: ", err));

        // Listen for incoming messages from SignalR
        connection.on("ReceiveNotification", (message) => {
            // Trigger Ant Design notification
            handleNotification(message);
        });

        // Cleanup on component unmount
        return () => {
            connection.stop().then(() => console.log("SignalR connection closed"));
        };
    }, [token]);

    return (
        <>
         {contextHolder}
         </>
    );
};

export default NotificationComponent;
