import React, { useEffect, useState, useCallback, startTransition } from 'react';
import { Table, Card, Flex, Button, Typography, Tag, Modal, Form, Input, Select, message, Dropdown, Spin } from 'antd';
import { getUserList, getRoles, inviteUser, deactivateUser, activateUser } from '../../redux/actions/UserAction';
import { useDispatch, useSelector } from 'react-redux';
import { PlusOutlined, ReloadOutlined, SettingOutlined } from '@ant-design/icons';
import './manage-user.scss';
import { formatDate } from '../../util';
import { useTranslation } from 'react-i18next'; // Import useTranslation
const { Title } = Typography;

const ManageUsers = () => {
    const { t, i18n } = useTranslation('users',{useSuspense: false}); // Initialize useTranslation with 'users' namespace

    const [users, setUsers] = useState([]);
    //const sites = useSelector((state) => state.site.sites);
    const currentSite = useSelector((state) => state.site.currentSite);
    const [pageSize, setPageSize] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [fetchedCount, setFetchedCount] = useState(false);
    const dispatch = useDispatch();
    //const { siteCode } = useParams();
    const [roles, setRoles] = useState([]);
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [form] = Form.useForm();
    const [columns, setColumns] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [isRefreshing, setIsRefreshing] = useState(false);

    const fetchUserList = async () => {
        setIsRefreshing(true);
        const siteId = currentSite ? currentSite.id : null;
        if (!siteId) {
            setIsRefreshing(false);
            return;
        }

        const request = {
            siteId: siteId,
            filterInfo: {
                offset: currentPage - 1,
                limit: pageSize,
                requiredTotal: !fetchedCount
            }
        };

        try {
            const response = await dispatch(getUserList(request));
            if (response.data && response.data.userList) {
                setUsers(response.data.userList);
                if (!fetchedCount) {
                    setTotalCount(response.data.totalRecods);
                    setFetchedCount(true);
                }

                if (selectedUser) {
                    const user = response.data.userList.find(x => x.id === selectedUser.id);
                    setSelectedUser(user);
                }
            }
        } catch (error) {
            console.error('Failed to fetch user list:', error);
        } finally {
            setIsRefreshing(false);
        }
    };

    function renderColumns() {
        const tableColumns = [
            {
                title: t('user'), // Use localized value
                key: 'fullName',
                render: (status) => (
                    <Flex vertical="true" className='user-display-section'>
                        <span className='user-fullname'>{status.fullName}</span>
                        <span className='user-email'>{status.userName}</span>
                    </Flex>
                ),
            },
            {
                title: t('role'), // Use localized value
                key: 'role',
                dataIndex: 'role',
                render: (role) => (
                    <Flex vertical="true" className='user-display-section'>
                        <span >{role}</span>
                    </Flex>
                ),
            },
            {
                title: t('teams'), // Use localized value
                key: 'teams',
                render: (status) => (
                    <Flex gap="4px 0" wrap>
                        <Tag color="green"><span >Admin</span></Tag>
                    </Flex>
                ),
            },
            {
                title: t('lastActive'), // Use localized value
                key: 'lastActive',
                dataIndex: 'lastActive',
                render: (lastActive) => (
                    <Flex gap="4px 0" wrap>
                        <span >{formatDate(lastActive)}</span>
                    </Flex>
                ),
            },
            {
                title: t('dateAdded'), // Use localized value
                key: 'createdAt',
                dataIndex: 'createdAt',
                render: (createdAt) => (
                    <Flex gap="4px 0" wrap>
                        <span >{formatDate(createdAt)}</span>
                    </Flex>
                ),
                sorter: (a, b) => new Date(a.created) - new Date(b.created),
            },
            {
                title: t('status'), // Use localized value
                key: 'status',
                dataIndex: 'status',
                render: (status) => showUserStatus(status),
            }
        ];
        setColumns(tableColumns);
    }

    useEffect(() => {
        renderColumns();
    }, [ t, i18n.language]);

    useEffect(() => {
        if (currentSite) {  // Only fetch if sites data is available
                fetchUserList();
        }
    }, [currentPage]);

    function showUserStatus(status) {
        return (status === 2 ? <Flex align="center"><span className="status-icon success"></span>{t('active')}</Flex>
            : status === 3 ?
                <Flex align="center"><span className="status-icon error"></span>{t('deactivated')}</Flex>
                : <Flex align="center"><span className="status-icon progress"></span>{t('inviteSent')}</Flex>);
    }


    const handleUserStatusChange = useCallback(async (action, actionType) => {
        const actionFunction = actionType === 'activate' ? activateUser : deactivateUser;
        const response = await dispatch(actionFunction({
            userId: selectedUser.id,
            siteId: currentSite ? currentSite.id : null
        }));

        if (response.status && response.data) {
            message.success(`${selectedUser.fullName} has been ${action}.`);
            startTransition(() => {
                handleManualRefresh();
            });
        } else {
            message.error("Oops!!! Something went wrong. Please try again later.");
        }
    }, [dispatch, selectedUser]);

    const handleActivate = useCallback(() => startTransition(() => handleUserStatusChange('activated', 'activate')), [handleUserStatusChange]);
    const handleDeactivate = useCallback(() => startTransition(() => handleUserStatusChange('deactivated', 'deactivate')), [handleUserStatusChange]);

    const getActionItems = useCallback((selectedUser) => {
        if (!selectedUser) return [];

        return [
            {
                key: '1',
                label: t('activate'), // Use localized value
                disabled: selectedUser.status === 2,
                onClick: handleActivate
            },
            {
                key: '2',
                label: t('deactivate'), // Use localized value
                disabled: selectedUser.status === 3,
                onClick: handleDeactivate
            }
        ];
    }, [handleActivate, handleDeactivate, selectedUser]);

    function handlePaginationChange(page, size) {
        setPageSize(size);
        setCurrentPage(page);
    }

    useEffect(() => {
        const fetchRoles = () => {
            startTransition( async () => {
                const response = await dispatch(getRoles());
                if (response.status) {
                    setRoles(response.data);
                }
            });
        };

        fetchRoles();
    }, [dispatch]);

    function onRowSelectionChange(selectedRowKeys, selectedRows) {
        setSelectedUser(selectedRows[0]);
    }

    const showModal = () => {
        setOpen(true);
    };

    const handleOk = async () => {
        try {
            setConfirmLoading(true);
            const formValues = await form.validateFields();
            const siteId = currentSite ? currentSite.id : null;

            const request = {
                userEmail: formValues.username,
                userRoles: [formValues.role],
                firstName: formValues.firstName,
                lastName: formValues.lastName,
                userSites: [siteId]
            };

            const response = await dispatch(inviteUser(request));

            if (response.status && (response.data && response.data.success)) {
                message.success(`Invite has been sent to ${request.userEmail}`);
                handleManualRefresh();
            }
            else {
                const messageText = response.data && response.data.message
                    ? response.data.message : `Oops!!! User invite action failed. Please try after sometime.`;
                message.error(messageText);
            }

        } catch (error) {
            console.error('Validation failed:', error);
        } finally {
            setOpen(false);
            setConfirmLoading(false);
            form.resetFields();
        }
    };

    const handleCancel = () => {
        console.log('Clicked cancel button');
        setOpen(false);
    };

    const UserForm = ({ roles, onFinish, form }) => {
        return (
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
            >
                <Form.Item
                    name="username"
                    label="Username"
                    rules={[{ required: true, message: 'Please input username!' }]}
                >
                    <Input placeholder="Enter username" />
                </Form.Item>

                <Form.Item
                    name="firstName"
                    label="First Name"
                    rules={[{ required: true, message: 'Please input first name!' }]}
                >
                    <Input placeholder="Enter first name" />
                </Form.Item>

                <Form.Item
                    name="lastName"
                    label="Last Name"
                    rules={[{ required: true, message: 'Please input last name!' }]}
                >
                    <Input placeholder="Enter last name" />
                </Form.Item>

                <Form.Item
                    name="role"
                    label="Role"
                    rules={[{ required: true, message: 'Please select a role!' }]}
                >
                    <Select placeholder="Select a role">
                        {roles.map(role => (
                            <Select.Option key={role.name} value={role.name}>
                                {role.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Form>
        );
    };

    // Separate function for manual refresh
    const handleManualRefresh = useCallback(() => {
        setFetchedCount(false);
        startTransition(() => {
            fetchUserList();
        });
    }, [fetchUserList]);

    return (
        <>  
            <Card className='manage-user-sections'>
                <Flex justify='space-between' className='header-section' align='center'>
                    <Title className='header' level={5}>{t('allUsers')}</Title> {/* Use localized value */}
                    <Flex gap="small">
                        <Button>{t('filter')}</Button> {/* Use localized value */}
                        <Dropdown
                            menu={{
                                items: getActionItems(selectedUser),
                                disabled: isRefreshing
                            }}
                            placement="bottomLeft"
                            arrow
                            trigger={['click']}
                            disabled={!selectedUser}
                        >
                            <div>
                                <Button disabled={!selectedUser}>{t('actions')}</Button> {/* Use localized value */}
                            </div>
                        </Dropdown>
                        <Button type="primary" icon={<PlusOutlined />} onClick={showModal}>{t('addUser')}</Button> {/* Use localized value */}
                        <Button
                            icon={<ReloadOutlined />}
                            onClick={handleManualRefresh}
                        />
                        <Button
                            icon={<SettingOutlined />}
                        />
                    </Flex>
                </Flex>
                <Table
                    className='list-detail-table'
                    bordered={true}
                    columns={columns}
                    dataSource={users}
                    loading={isRefreshing}
                    pagination={{ defaultPageSize: 5, onChange: handlePaginationChange, total: totalCount, showSizeChanger: true, pageSizeOptions: ['5', '10', '20'], }}
                    rowKey="id"
                    rowSelection={
                        {
                            type: 'radio',
                            onChange: onRowSelectionChange,
                            columnWidth: 48,
                        }
                    }
                />
            </Card>

            <Modal
                title={t('addUser')} // Use localized value
                open={open}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            >
                <UserForm
                    roles={roles}
                    onFinish={handleOk}
                    form={form}
                />
            </Modal>
        </>
    );
};

export default ManageUsers;
