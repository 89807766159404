import axios from 'axios';
import authToken from './AuthToken';

const apiClient = axios.create({
  baseURL: "http://54.244.119.79/api", 
  headers: {
    'Content-Type': 'application/json',
  },
});
const pathList = ['/login', '/signup', '/Verify-email'];

apiClient.interceptors.request.use(
  (config) => {
    const requestPath = config.url.replace('/api', '');
    if (!pathList.includes(requestPath)) {
      const token = authToken.getItem('access-token');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        authToken.removeItem('access-token');
        console.error('Unauthorized, please log in');
      }
    } else if (error.request) {
      console.error('Network error, please try again');
    }
    return Promise.reject(error);
  }
);

export default apiClient;
