import React, { useEffect, useState } from "react";
import { analyticalData } from '../../redux/actions/SchedulerRunActions';
import { Statistic, Flex, Empty, Progress } from "antd";
import BarChartComponent from "./charts/BarComponent";
import dashboardSettings from './dashboard_settings.json';
import AreaChartComponent from "./charts/AreaComponent";

function CardComponent(props) {
    const { secondaryWidget, properties, currentRunId } = props;
    const [cardValue, setCardValue] = useState(null);
    const [secondaryWidgetValue, setSecondaryWidgetValue] = useState(null);
    const secondaryWidgetProperties = dashboardSettings.find(item => item.settings.ReportName === secondaryWidget?.reportName)?.settings;

    useEffect(() => {
        async function getAnalyticData() {
            const response = await analyticalData(currentRunId, properties.ReportName);
            if (response.status) {
                setCardValue(response.data[properties.ReportName])
            }
        }
        async function getChartData() {
            const response = await analyticalData(currentRunId, secondaryWidget.reportName);
            if (response.status && response.data) {
                const aggregatedData = getAggregatedData(response.data)
                const widgetData = Object.values(aggregatedData);
                setSecondaryWidgetValue(widgetData);
            }
        }
        getAnalyticData();
        if(secondaryWidget && secondaryWidget.displayType !== 'progress') {
            getChartData(secondaryWidget);
        }
    }, [currentRunId, properties.ReportName])

    function getAggregatedData(data) {
        return data.reduce((acc, item) => {
            const date = item[secondaryWidgetProperties.Axes.x.column]?.split("T")[0];
            if (!acc[date]) {
                acc[date] = { [secondaryWidgetProperties.Axes.x.column]: date, [secondaryWidgetProperties.Axes.y.column]: 0 };
            }
            acc[date][`${secondaryWidgetProperties.Axes.y.column}`] += item[`${secondaryWidgetProperties.Axes.y.column}`];

            return acc;
        }, {});
    }

    function applyFormat(value, format) {
        value = formatValue(value, format.type);
        value = applyValueFormat(value, format.format);
        return value;
    }

    function applyValueFormat(value, format) {
        if (format === 'currency') {
            return `$${value}`;
        }
        return value;
    }

    function formatValue(value, type) {
        if (type === "percentage") {
            return `${parseInt(value * 100)}%`;
        }
        else if (type === "integer") {
            return value.toLocaleString();
        }
        return value;
    }

    function renderSecondaryWidget() {
        switch (secondaryWidget.displayType) {
            case "progress":
                return (
                    <Progress 
                        strokeColor={secondaryWidget.Color} 
                        className="progress-display" 
                        strokeLinecap="butt" 
                        percent={parseInt(cardValue * 100)} 
                        showInfo={false} 
                    />
                );
            case "area":
                return (
                    <AreaChartComponent 
                        showAxes={false} 
                        data={secondaryWidgetValue} 
                        properties={secondaryWidgetProperties} 
                    />
                );
            case "bar":
                return (
                    <BarChartComponent 
                        showAxes={false} 
                        data={secondaryWidgetValue} 
                        properties={secondaryWidgetProperties} 
                    />
                );
            // Add more cases here if needed
            default:
                return null;
        }
    };

    return (
        (cardValue || cardValue === 0) ? 
        (
            <Flex vertical className="statistic-container">
                <Statistic 
                    className={!secondaryWidget ? `primary-stat-value` : 'secondary-stat-value'}
                    value={applyFormat(cardValue, properties.Format)} 
                />
                {secondaryWidget ? renderSecondaryWidget() : null}
            </Flex>
        ) : <Flex align="center" justify="center" className="empty-data-placeholder"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></Flex>
    )
}

export default CardComponent;