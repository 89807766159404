import React, { useState, useEffect, useRef } from 'react';
import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';
import { Button, Dropdown, Flex } from 'antd';
import {HighlightOutlined,ZoomInOutlined,ZoomOutOutlined,EyeOutlined} from "@ant-design/icons";
import gantt from 'dhtmlx-gantt';
import './gantt-chart.scss';
import {colors,highlightColors} from './GanttColors';

function GanttChart(props) {
    const {data} = props;
    const {input_tables,output_tables} = data;
    const outputTableData = output_tables.schedule_detail;
    const ganttContainer = useRef(null);
    const [viewBy, setViewBy] = useState('resourceTask');
    const [highlightBy, setHighlightBy] = useState('none');
    const [currentZoomLevel, setCurrentZoomLevel] = useState(4);
    const [viewByActive, setViewByActive] = useState(false);
    const [highlightByActive, setHighlightByActive] = useState(false);

    const viewByItems = [
        {
          label: "Resource and Task",
          key: 'resourceTask',
          onClick: () => setViewBy('resourceTask')
        },
        {
          label: "Resource",
          key: 'resource',
          onClick: () => setViewBy('resource')
        },
        {
          label: 'Task',
          key: 'task',
          onClick: () => setViewBy('task')
        },
        {
            label: 'Product',
            key: 'product',
            onClick: () => setViewBy('product')
        },
        {
            label: 'Demand',
            key: 'demandId',
            onClick: () => setViewBy('demandId')
        },
    ];

    const highlightItems = [
        {
          label: "None",
          key: 'none',
          onClick: () => setHighlightBy('none')
        },
        {
          label: "Resource",
          key: 'resource',
          onClick: () => setHighlightBy('resource')
        },
        {
          label: 'Task',
          key: 'task',
          onClick: () => setHighlightBy('task')
        },
        {
            label: 'Product',
            key: 'product',
            onClick: () => setHighlightBy('product')
        },
        {
            label: 'Demand',
            key: 'demandId',
            onClick: () => setHighlightBy('demandId')
        },
    ];

    function handleSelectViewBy(resourceId=null,taskId=null,jobId=null,productId=null,demandId=null) {
        const filterParams = {
            'resourceTask' : {columns: [{name: "open", label: "", width: 14, template: gantt.templates.grid_open},{name:'resource_name',label:'Resource & Task', width: 250}], viewId: `r-${resourceId}/t-${taskId}`, parentId: `resource-${resourceId}`, showLinks: true},
            'resource': {columns: [{name:'resource_name',label:'Resource', width: 150}], viewId:`r-${resourceId}`, parentId: `resource-${resourceId}`, showLinks: false},
            'task': {columns: [{name:'task_name',label:'Task', width: 150}], viewId: `t-${taskId}`, parentId: `task-${taskId}`, showLinks: true},
            'product': {columns: [{name:'product_name',label:'Product', width: 150}], viewId: `p-${productId}`, parentId: `product-${productId}`, showLinks: false},
            'demandId': {columns: [{name:'demand_id',label:'DemandId', width: 150}], viewId: `d-${demandId}`, parentId: `demand-${demandId}`, showLinks: false}
        }

        return filterParams[viewBy];
    }

    useEffect(() => {
        gantt.config.date_format = "%Y-%m-%d %H:%i";
        gantt.config.duration_unit = "minute";
        gantt.config.width = 2000;
        gantt.config.order_branch = true;
        gantt.config.fit_tasks = true;

        gantt.config.scales = [
            {unit: "hour", step: 1, format: "%H:%i"}
        ];

        const currentSelectedParam = handleSelectViewBy();
        gantt.config.columns = currentSelectedParam.columns;

        gantt.templates.task_class = function (start, end, task) {
            if(highlightBy === 'none') {
                const colorIndex = task.job_id % colors.length
                const className = `job-${colorIndex}`;
                return colorIndex < 5 ? `color-dark ${className}` : `color-light ${className}`;
            }
            else {
                const highlightByMapping = {
                    'resource': 'resource_id',
                    'task': 'task_id',
                    'product': 'product_id',
                    'demandId': 'demand_id'
                }
                const currentHighlightByValue = highlightByMapping[highlightBy]
                const colorIndex = task[currentHighlightByValue] % highlightColors.length;
                const className = `highlight-task-${colorIndex}`;
                return `color-dark ${className}`;
            }
        };

        gantt.templates.grid_row_class = function(start, end, task){
            if (task.$level === 1) {
                return "children-task";
            }
        };

        gantt.templates.grid_open = function(task) {
            if (task.$level === 0) {
                return `
                    <div class='gantt_tree_icon' style="width: 10px; height: 10px;">
                        <svg width="10" height="10" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path d="${task.$open ? 'M7 10l5 5 5-5z' : 'M7 14l5-5 5 5z'}"/>
                        </svg>
                    </div>`;
            }
            return "";
        };

        const zoomConfig = {
            levels: [
                {
                    name: "10min",
                    scale_height: 30,
                    scales: [
                        { unit: "hour", step: 1, format: "%d %M", sticky: true },
                        {unit: "minute", step: 10, format:'%H:%i'},
                    ]
                },
                {
                    name: "15min",
                    scale_height: 30,
                    scales: [
                        { unit: "hour", step: 1, format: "%d %M", sticky: true },
                        {unit: "minute", step: 15, format:'%H:%i'},
                    ]
                },
                {
                    name: "30min",
                    scale_height: 30,
                    scales: [
                        { unit: "hour", step: 1, format: "%d %M", sticky: true },
                        {unit: "minute", step: 30, format:'%H:%i'},
                    ]
                },
                {
                    name: "hour",
                    scale_height: 30,
                    scales: [
                        { unit: "hour", step: 1, format: "%d %M"},
                        {unit: "hour", step: 1, format:'%H:%i'}
                    ]
                },
                {
                    name: "week",
                    scale_height: 30,
                    scales: [
                        {
                            unit: "week", step: 1, format: function (date) {
                                var dateToStr = gantt.date.date_to_str("%d %M");
                                var endDate = gantt.date.add(date, 6, "day");
                                var weekNum = gantt.date.date_to_str("%W")(date);
                                return "week " + weekNum + ", " + dateToStr(date) + " - " + dateToStr(endDate);
                            }
                        },
                        { unit: "day", step: 1, format: "%j %D" }
                    ]
                },
                {
                    name: "month",
                    scale_height: 30,
                    scales: [
                        { unit: "month", format: "%F, %Y" },
                        { unit: "week", format: "Week %W" }
                    ]
                },
                {
                    name: "quarter",
                    scale_height: 30,
                    scales: [
                        {
                            unit: "quarter", step: 1, format: function (date) {
                                var dateToStr = gantt.date.date_to_str("%M");
                                var endDate = gantt.date.add(gantt.date.add(date, 3, "month"), -1, "day");
                                return dateToStr(date) + " - " + dateToStr(endDate);
                            }
                        },
                        { unit: "year", step: 1, format: "%Y" }
                    ]
                },
                {
                    name: "year",
                    scale_height: 30,
                    scales: [
                        { unit: "year", step: 1, format: "%Y" }
                    ]
                }
            ]
        };

        gantt.ext.zoom.init(zoomConfig);
        gantt.ext.zoom.setLevel(currentZoomLevel);
        gantt.config.drag_move = false;
        gantt.config.drag_progress = false;
        gantt.config.drag_resize = false;
        gantt.config.drag_links = false;
        gantt.config.readonly = true;

        if(!gantt._eventsAttached){
            gantt._eventsAttached = true;
        
            gantt.attachEvent("onTaskClick", function(id, e) {
                e.preventDefault();
                const task = gantt.getTask(id);
                if (task.$level === 0) {
                    if (task.$open) {
                        gantt.close(id);
                    } else {
                        gantt.open(id);
                    }
                }
                return true;
            });

            gantt.attachEvent("onTaskLoading", function (task) {
                if (task.hide){
                    task.hide_bar = true
                }
                return true
            });
        }

        gantt.init(ganttContainer.current);
        const tasks = transformDataToGanttFormat(input_tables,outputTableData);
        gantt.parse(tasks);
    
        return () => {
          gantt.clearAll();
        };
    }, [data, viewBy,highlightBy]);

    function zoomOut() {
        gantt.ext.zoom.zoomOut();
        setCurrentZoomLevel(gantt.ext.zoom.getCurrentLevel())
    }

    function zoomIn() {
        gantt.ext.zoom.zoomIn();
        setCurrentZoomLevel(gantt.ext.zoom.getCurrentLevel())
    }

    function formatDateToCustomFormat(dateString) {
        const date = new Date(dateString);
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const day = String(date.getUTCDate()).padStart(2, '0');
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');
      
        return `${year}-${month}-${day} ${hours}:${minutes}`;
    }

    function getPreviousTaskDetails(details) {
        const previousTaskDetails = details.split(',').map(part => part.trim());
        const [previousDemand,previousJob,previousTask] = previousTaskDetails.slice(0, 3);
        const resource = details.match(/\((\d+),/);
        const previousResource = resource ? resource[1] : null;
        return {previousDemand, previousJob, previousTask, previousResource};
    }

    const transformDataToGanttFormat = (inputTableData,data) => {
        const taskData = [];
        const links = [];
        const uniqueTasks = {};
    
        data.forEach((task, index) => {
            const { task_id, task_start_time, task_end_time, resource_id, job_id, demand_id, product_id } = task;
            const resourceName = inputTableData.resource.find(resource => resource.resource_id === resource_id).resource_name
            const taskName = inputTableData.task.find(task => task.task_id === task_id).task_name
            const productName = inputTableData.product.find(product => product.product_id === product_id).product_shortname
            const currentViewByParams = handleSelectViewBy(resource_id,task_id,job_id,product_id,demand_id)
            const currentTaskKey = currentViewByParams.viewId
            const isDisplayArrows = currentViewByParams.showLinks

            if (!uniqueTasks[currentTaskKey]) {
                uniqueTasks[currentTaskKey] = {
                    id: currentViewByParams.parentId,
                    text: productName,
                    open: true,
                    parent: 0,
                    render: viewBy === 'resourceTask' ? "project" : "split",
                    task_name: taskName,
                    resource_name: resourceName,
                    job_id: job_id,
                    resource_id: resource_id,
                    task_id: task_id,
                    product_id: product_id,
                    product_name: productName,
                    demand_id: demand_id,
                    hide: viewBy === 'resourceTask' ? true : false
                };
                taskData.push(uniqueTasks[currentTaskKey]);
            }

            if(viewBy === "resourceTask") {
                if (!uniqueTasks[`task-${resource_id}-${task_id}`]) {
                    uniqueTasks[`task-${resource_id}-${task_id}`] = {
                        id: `task-${resource_id}-${task_id}`,
                        text: productName,
                        open: true,
                        parent: currentViewByParams.parentId,
                        render: "split",
                        task_name: taskName,
                        resource_name: taskName,
                        job_id: job_id,
                        resource_id: resource_id,
                        task_id: task_id,
                        product_id: product_id,
                        product_name: productName,
                        demand_id: demand_id
                    };
                    taskData.push(uniqueTasks[`task-${resource_id}-${task_id}`]);
                }
    
                taskData.push({
                    id: `task-${demand_id}-${job_id}-${resource_id}-${task_id}`,
                    text: productName,
                    start_date: formatDateToCustomFormat(task_start_time),
                    end_date: formatDateToCustomFormat(task_end_time),
                    duration: (new Date(task_end_time) - new Date(task_start_time)) / 1000,
                    parent: `task-${resource_id}-${task_id}`,
                    job_id: job_id,
                    resource_id: resource_id,
                    task_id: task_id,
                    product_id: product_id,
                    demand_id: demand_id
                });
            }
            else {
                taskData.push({
                    id: `task-${demand_id}-${job_id}-${resource_id}-${task_id}`,
                    text: productName,
                    start_date: formatDateToCustomFormat(task_start_time),
                    end_date: formatDateToCustomFormat(task_end_time),
                    duration: (new Date(task_end_time) - new Date(task_start_time)) / 1000,
                    parent: currentViewByParams.parentId,
                    job_id: job_id,
                    resource_id: resource_id,
                    task_id: task_id,
                    product_id: product_id,
                    demand_id: demand_id
                });
            }

            if(task.previous_task_assignment_tuples && isDisplayArrows) {
                const {previousDemand,previousJob,previousTask,previousResource} = getPreviousTaskDetails(task.previous_task_assignment_tuples)
                links.push({id: index, source: `task-${previousDemand}-${previousJob}-${previousResource}-${previousTask}`, target: `task-${demand_id}-${job_id}-${resource_id}-${task_id}`, type: "0"})
            }
        });
    
        return { tasks: taskData, links: links };
    };

    return (
        <div>
            <Flex className="zoom-btn-container" gap={13} align='center' justify='end'>
                <Dropdown menu={{items: highlightItems}} trigger={['click']} onOpenChange={(open) => setHighlightByActive(open)}>
                    <Button className={`highlightby-btn ${highlightByActive && 'btn-active'}`} icon={<HighlightOutlined />}>Highlight by</Button>
                </Dropdown>
                <Dropdown menu={{items: viewByItems}} trigger={['click']} onOpenChange={(open) => setViewByActive(open)}>
                    <Button className={`viewby-btn ${viewByActive && 'btn-active'}`} icon={<EyeOutlined />}>View by</Button>
                </Dropdown>
                <Flex gap={8} align='center'>
                    <Button className='zoom-btn' onClick={zoomIn} icon={<ZoomInOutlined />}/>
                    <Button className='zoom-btn' onClick={zoomOut} icon={<ZoomOutOutlined />}/>
                </Flex>
            </Flex>
            <div
                ref={ganttContainer}
                style={{ width: '100%', height: '500px' }}
            ></div>
        </div>
    );
}

export default GanttChart;