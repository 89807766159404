import React from "react";
import tableMappings from "../../scheduler/scheduler_table_settings.json";
import { formatDate } from "../../../util";
import {Flex,Row,Col,Divider} from 'antd';
import EditableField from "../../common/EditableField";

function RunDetailGridView(props) {
    const {currentRunDetail,runDetailKey = null,allowEditing = false} = props;
    let runDetailMappings;

    if(runDetailKey) {
        runDetailMappings = tableMappings.run_details.find(field => field.name === runDetailKey)?.properties;
    }
    else {
        runDetailMappings = tableMappings.run_details.filter(item => !item.properties)
    }

    const handleParameterChange = (runParamObj, updatedParameterValue, runParam=null) => {
        runParam ? currentRunDetail[runParamObj][runParam] = updatedParameterValue : currentRunDetail[runParamObj] = updatedParameterValue;
    };

    return (
        <Row gutter={[8, 8]} className="detail-view">
            {runDetailMappings.filter(item => item.visibility === 'Show').map((field, index) => {
                const value = currentRunDetail[field.name];
                
                if(typeof value !== 'object' || value === null) {
                    const formattedValue = field.isDateField ? formatDate(value) : typeof value === 'number' ? Math.floor(value * 10) / 10 : String(value);
                    return (
                        <Col span={6} key={index} className="detail-cell">
                            <Flex className="detail-field" align="center">
                                <strong className="field-key"><span>{field.label}</span><span>:</span></strong>
                                {allowEditing ? (
                                    <EditableField
                                        value={String(value)}
                                        type={typeof value === 'boolean' ? 'boolean' : typeof value === 'number' ? 'number' : 'string'}
                                        onChange={(newValue) => handleParameterChange(field.name, newValue)}
                                        isDateField={field.isDateField}
                                    />
                                ) : (
                                    <div className="field-value"><div className="input-value">{value !== null ? formattedValue : 'N/A'}</div></div>
                                )}
                            </Flex>
                        </Col>
                    );
                }

                else {
                    return (
                        <Col span={24} key={index}>
                            <Divider />
                            <h3>{field.label}</h3>
                            <Row gutter={[8, 8]}>
                                {field.properties.filter(item => item.visibility === 'Show').map((runParam, runParamIndex) => {
                                    const subFieldValue = value[runParam.name];
                                    const formattedValue = runParam.isDateField ? formatDate(subFieldValue) : typeof subFieldValue === 'number' ? Math.floor(subFieldValue * 10) / 10 : String(subFieldValue);
                                    return (
                                        <Col span={6} key={runParamIndex} className="detail-cell">
                                            <Flex className="detail-field" align="center">
                                                <strong className="field-key"><span>{runParam.label}</span><span>:</span></strong>
                                                {allowEditing ? (
                                                    <EditableField
                                                        value={String(subFieldValue)}
                                                        type={typeof subFieldValue === 'boolean' ? 'boolean' : typeof subFieldValue === 'number' ? 'number' : 'string'}
                                                        onChange={(newValue) => handleParameterChange(field.name, newValue, runParam.name)}
                                                    />
                                                ) : (
                                                    <div className="field-value"><div className="input-value">{subFieldValue !== null ? formattedValue : 'N/A'}</div></div>
                                                )}
                                            </Flex>
                                        </Col>
                                    )
                                })}
                            </Row>
                        </Col>
                    );
                }

            })}
        </Row>
    )
}

export default RunDetailGridView;