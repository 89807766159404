import { BarChartOutlined } from "@ant-design/icons";


export const DashboardWidgetMenus = [
    {
        key: 'card',
        type: 'group',
        label: 'KPI Cards',
        children: [
            {
                key: 'overall_utilization',
                label: 'Overall Utilization',
                settings: {
                    "Title": "Overall Utilization",
                    "ReportName": "overall_utilization",
                    "Format": {
                        "type": "percentage"
                    },
                    "Color": "red"
                },
                dashboardType: "card",
                secondaryWidget: null
            },
            {
                key: 'highest_resource_utilization',
                label: 'Highest Resource Utlization',
                settings: {
                    "Title": "Highest Utilization",
                    "ReportName": "highest_resource_utilization",
                    "Format": {
                        "type": "percentage"
                    },
                    "Color": "red"
                },
                dashboardType: "card",
                secondaryWidget: {
                    "displayType": "progress",
                    "type": "",
                    "Color": "#13C2C2"
                }
            },
            {
                key: 'forecasted_throughput',
                label: 'Forecasted Throughput',
                settings: {
                    "Title": "Forecasted Throughput",
                    "ReportName": "forecasted_throughput",
                    "Format": {
                        "type": "integer"
                    },
                    "Color": "red"
                },
                dashboardType: "card",
                secondaryWidget: {
                    "displayType": "bar",
                    "type": "",
                    "reportName": "forecasted_daily_throughput"
                }
            },
            {
                key: 'scheduled_starts',
                label: 'Scheduled Starts',
                settings: {
                    "Title": "Scheduled Starts",
                    "ReportName": "scheduled_starts",
                    "Format": {
                        "type": "integer"
                    },
                    "Color": "green"
                },
                dashboardType: "card",
                secondaryWidget: {
                    "displayType": "area",
                    "type": "",
                    "reportName": "scheduled_daily_starts"
                }
            },
            {
                key: 'active_resource_count',
                label: 'Active Resource Count',
                settings: {
                    "Title": "Active Resource Count",
                    "ReportName": "active_resource_count",
                    "Format": {
                        "type": "integer"
                    },
                    "Color": "green"
                },
                dashboardType: "card"
            },
            {
                key: 'projected_revenue',
                label: 'Projected Revenue',
                settings: {
                    "Title": "Projected Revenue",
                    "ReportName": "projected_revenue",
                    "Format": {
                        "type": "integer",
                        "format": "currency"
                    },
                    "Color": "green"
                },
                dashboardType: "card"
            },
            {
                key: 'expected_margin',
                label: 'Expected Margin',
                settings: {
                    "Title": "Expected Margin",
                    "ReportName": "expected_margin",
                    "Format": {
                        "type": "integer",
                        "format": "currency"
                    },
                    "Color": "green"
                },
                dashboardType: "card"
            },
            {
                key: 'forecasted_waiting_time',
                label: 'Forecasted Waiting Time',
                settings: {
                    "Title": "Forecasted Waiting Time",
                    "ReportName": "forecasted_waiting_time",
                    "Format": {
                        "type": "integer",
                    },
                    "Color": "green"
                },
                dashboardType: "card"
            },
            {
                key: 'earliest_start_penalty_sum',
                label: 'Early start Penalty Sum',
                settings: {
                    "Title": "Early start Penalty Sum",
                    "ReportName": "earliest_start_penalty_sum",
                    "Format": {
                        "type": "integer",
                    },
                    "Color": "green"
                },
                dashboardType: "card"
            },
            {
                key: 'latest_end_penalty_sum',
                label: 'Late Finish Penalty Sum',
                settings: {
                    "Title": "Late Finish Penalty Sum",
                    "ReportName": "latest_end_penalty_sum",
                    "Format": {
                        "type": "integer",
                    },
                    "Color": "green"
                },
                dashboardType: "card"
            }
        ],
    },
    {
        key: 'chart',
        type: 'group',
        label: 'Charts',
        icon: <BarChartOutlined />,
        children: [
            {
                key: 'utilized_resources_top_ten',
                label: 'Top 10 Resource Utilized',
                disabled: true,
                "settings": {
                    "Title": "Top 10 Utilized Resources",
                    "ReportName": "utilized_resources_top_ten",
                    "chartType": "bar",
                    "Axes": {
                        "x": {
                            "title": "Resource",
                            "column": "resource"
                        },
                        "y": {
                            "title": "Utilization",
                            "column": "utilization"
                        }
                    },
                    "Color": "#82ca9d"
                }
            },
            {
                key: 'forecasted_daily_throughput',
                label: 'Forecasted Daily Throughput',
                disabled: true,
                "settings": {
                    "Title": "Forecast Daily Throughput",
                    "ReportName": "forecasted_daily_throughput",
                    "isStacked": true,
                    "chartType": "stacked",
                    "Axes": {
                        "x": {
                            "title": "Start Date",
                            "column": "bucket start time",
                            "isDateTime": true
                        },
                        "y": {
                            "title": "Units Completed",
                            "column": "units completed"
                        },
                        "series": {
                            "title": "Product",
                            "column": "product name"
                        }
                    },
                    "Color": "#8884d8"
                }
            },
            {
                key: 'scheduled_daily_starts',
                label: 'Scheduled Daily Starts',
                "settings": {
                    "Title": "Scheduled Daily Starts",
                    "ReportName": "scheduled_daily_starts",
                    "isStacked": true,
                    "chartType": "stacked",
                    "Axes": {
                        "x": {
                            "title": "Start Date",
                            "column": "bucket start time",
                            "isDateTime": true
                        },
                        "y": {
                            "title": "Units Started",
                            "column": "units started"
                        },
                        "series": {
                            "title": "Product",
                            "column": "product name"
                        }
                    },
                    "Color": "#8884d8"
                }
            },
            {
                key: 'projected_product_revenue',
                label: 'Projected Product Revenue',
                disabled: true,
                "settings": {
                    "Title": "Projected Product Revenue",
                    "ReportName": "projected_product_revenue",
                    "chartType": "pie",
                    "Axes": {
                        "x": {
                            "title": "Product Name",
                            "column": "product name"
                        },
                        "y": {
                            "title": "Product Revenue",
                            "column": "product revenue"
                        },
                    },
                    "Color": "#8884d8"
                }
            },
            {
                key: 'expected_product_margin',
                label: 'Expected Product Margin',
                "settings": {
                    "Title": "Expected Product Margin",
                    "ReportName": "expected_product_margin",
                    "chartType": "pie",
                    "Axes": {
                        "x": {
                            "title": "Product Name",
                            "column": "product name"
                        },
                        "y": {
                            "title": "Product Margin",
                            "column": "product margin"
                        },
                    },
                    "Color": "#8884d8"
                }
            },
            {
                key: 'active_non_active_resource_count',
                label: 'Active vs. Non Actice Resource Count',
                "settings": {
                    "Title": "Active vs. Non Actice Resource Count",
                    "ReportName": "active_non_active_resource_count",
                    "chartType": "pie",
                    "Axes": {
                        "x": {
                            "title": "Resource",
                            "column": "dimension"
                        },
                        "y": {
                            "title": "Counts",
                            "column": "value"
                        },
                    },
                    "Color": "#8884d8"
                }
            }
        ],
    },
    {
        key: 'gantt',
        type: 'group',
        label: 'Gantts',
        children: [
            {
                key: 'demand_request_window',
                label: 'Demand request Window',
                disabled: true,
            },
            {
                key: 'schedule_by_resource',
                label: 'Schedule By Resource',
                disabled: true,
            },
            {
                key: 'schedule_by_product',
                label: 'Schedule By Product',
                disabled: true,
            },
            {
                key: 'schedule_by_demand',
                label: 'Schedule By Demand',
                disabled: true,
            },
        ],
    }
];

export const UpdateMenus = (layoutSettings) => {
    let menu = JSON.parse(JSON.stringify(DashboardWidgetMenus));
    menu.forEach(group => {
        if (group.key !== "gantt") {
            group.children = group.children.map(child => {
                const existsInLayout = layoutSettings.some(layout =>
                    layout.settings && layout.settings.ReportName === child.key
                );

                return { ...child, disabled: existsInLayout };
            });
        }
    });
    return menu;
};

export const getNextPosition = (layout, itemWidth) => {
    // Define the grid column count
    const gridCols = 12;

    // Initialize the maximum y-coordinate to place items on the lowest row available
    let maxY = 0;

    // Find the maximum x and y values for each row's layout
    layout.forEach(item => {
        if (item.y >= maxY) maxY = item.y + item.h; // Track the lowest row
    });

    // Now check for a spot in the last row (if space is available)
    let xPosition = 0;

    // Get last row's elements
    const lastRowItems = layout.filter(item => item.y === maxY - 1);

    // Calculate x-position based on last row items
    if (lastRowItems.length > 0) {
        xPosition = lastRowItems.reduce((acc, item) => Math.max(acc, item.x + item.w), 0);
    }

    // If the item fits on the same row
    if (xPosition + itemWidth <= gridCols) {
        return { x: xPosition, y: maxY - 1 };
    } else {
        // Otherwise, start a new row
        return { x: 0, y: maxY };
    }
}

