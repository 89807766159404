import React, { useState, useEffect } from "react";
import { Layout, Badge, Flex, Avatar, Dropdown, Menu } from 'antd';
import './header.scss';
import {
    SearchOutlined,
    QuestionCircleOutlined,
    BellOutlined,
    GlobalOutlined,
    LogoutOutlined,
    ProfileOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import profileImage from '../../../assets/profile.png'; // Import profile image
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom'; // Import useNavigate
const { Header } = Layout;

function MainHeader(props) {
    const { currentSite } = props;
    const { t, i18n } = useTranslation('main-header'); // Initialize useTranslation with 'main-header' namespace
    const [language, setLanguage] = useState(i18n.language); // Initialize language state
    const user = useSelector((state) => state.user.user);
    const navigate = useNavigate(); // Initialize useNavigate
    
    const getMenuItems = (t) => [
        { key: 'profile', label: t('profile'), icon: <ProfileOutlined /> }, // Profile menu item at the top
        { key: 'manage-sites', label: t('manage-sites'), icon: <ProfileOutlined /> },
        { key: 'logout', label: t('logout'), icon: <LogoutOutlined /> }
    ];

    const [menuItems, setMenuItems] = useState(getMenuItems(t));

    useEffect(() => {
        setMenuItems(getMenuItems(t));
    }, [t, i18n.language]);

    const handleMenuClick = (key) => {
        if (key === 'manage-sites') {
            navigate('/choose-site'); // Navigate to 'choose-sites' page
        }
    };

    const menu = (
        <Menu>
            {menuItems.map(item => (
                <Menu.Item 
                    key={item.key} 
                    icon={item.icon}
                    onClick={() => handleMenuClick(item.key)} // Use handleMenuClick method
                >
                    {item.label}
                </Menu.Item>
            ))}
        </Menu>
    );

    function toggleLanguage() {
        const newLanguage = language === 'en' ? 'es' : 'en';
        i18n.changeLanguage(newLanguage);
        setLanguage(newLanguage);
    }

    useEffect(() => {
        setLanguage(i18n.language);
    }, [i18n.language]);

    return (
        <Header className='top-header'>
            {
                currentSite ? (
                    <Flex className="side-header" justify="center" align="center">
                        <img className="company-logo" src={`data:image/png;base64,${currentSite.companyLogo}`} alt="company-logo"/>
                    </Flex>
                ) : (<></>)
            }
            <Flex className="header-items" gap={'small'} align="center" justify="end">
                <SearchOutlined className="header-icon"/>
                <QuestionCircleOutlined className="header-icon icon-top"/>
                <Badge count={99} overflowCount={10} size="small" className="header-icon icon-top count-badge">
                    <BellOutlined className="icon-bell"/>
                </Badge>
                <Dropdown overlay={menu} trigger={['click']}>
                    <div className="profile-info">
                        <Avatar src={profileImage} style={{ backgroundColor: '#87d068' }} />
                        <span className="profile-name">{user ? user.fullName : "UNKNOWN"}</span>
                    </div>
                </Dropdown>
                <div className="language-toggle" onClick={toggleLanguage}>
                    <GlobalOutlined className="header-icon" />
                    <span className="language-label">{language.toUpperCase()}</span>
                </div>
            </Flex>
        </Header>
    )
}

export default MainHeader;