import React, { useState, useEffect } from "react";
import { Input } from 'antd';
import DateTimePicker from "./DateTimePicker";

function EditableField({ value, onChange, type, isDateField }) {
    const [enableEdit, setEnableEdit] = useState(false);
    const [inputValue, setInputValue] = useState(value);
  
    const handleInputChange = (e) => {
      setInputValue(e.target.value);
    };

    function saveDateField(dateValue) {
      onChange(dateValue);
    }
  
    const handleBlurOrEnter = () => {
      setEnableEdit(false);
      let newValue;
      if (type === 'number') {
        newValue = Number(inputValue);
      } else if (type === 'boolean') {
        newValue = inputValue === 'true';
      } else {
        newValue = inputValue;
      }
      onChange(newValue);
    };
  
    const handleEditClick = (e) => {
      e.stopPropagation();
      if (!enableEdit) {
        setEnableEdit(true);
      }
    };
  
    return (
      <div className="field-value">
        {enableEdit ? (
            
            <Input
                className="input-field"
                value={inputValue}
                onChange={handleInputChange}
                onBlur={handleBlurOrEnter}
                onPressEnter={handleBlurOrEnter}
                autoFocus
            />
            
        ) : (
            isDateField ? (
              <DateTimePicker dateValue={value} saveDate={saveDateField}/>  
            ) : (
              <div className="input-value" onClick={handleEditClick}>{inputValue !== null ? inputValue : 'N/A'}</div>
            )
        )}
      </div>
    );
}

export default EditableField;