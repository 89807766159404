import React from "react";
import { List, Flex } from 'antd';

function TableViewComponent(props) {
    const {data,properties} = props;

    function renderPropertyValue(value) {
        if(properties.Axes.y.type === 'percentage') return `${parseInt(value * 100)}%`
        return value;
    }

    return (
        <List
            dataSource={data}
            split={false}
            className="list-view"
            grid={{
                gutter: 4,
                column: 1,
            }}
            renderItem={(item,index) => (
                <List.Item className="list-item">
                  <Flex justify="space-between" className="list-item-content">
                    <Flex align="center" gap={8}>
                        <Flex justify="center" align="center" className="row-index"><span className="index">{index + 1}</span></Flex>
                        <div>{item[properties.Axes.x.column]}</div>
                    </Flex>
                    <div>{renderPropertyValue(item[properties.Axes.y.column])}</div>
                  </Flex>
                </List.Item>
            )}
        />
    )

}

export default TableViewComponent;