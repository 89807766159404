import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AcceptInviteComponent from './components/auth/accept-invite';
import ChooseSiteComponent from './components/auth/choose-site';
import LoginComponent from './components/auth/login';
import SignUpComponent from './components/sign-up/signup';
import VerifyEmailComponent from './components/auth/verify-email';
import FetchUserSites from './components/fetch-user-sites/FetchUserSites';
import HomeComponent from './components/home/home';
import CompareRun from './components/scheduler/CompareRun';
import FindRuns from './components/scheduler/FindRuns';
import RunDetails from './components/scheduler/RunDetails';
import Settings from './components/sitedetails/SiteDetails';
import ManageUsers from './components/user/manage-user';
import AuthGuard from './AuthGuard';

const RoutesConfig = () => {
    return (
        <Routes>
            <Route path='/login' element={<LoginComponent />} />
            <Route path='/' element={<AuthGuard><FetchUserSites><HomeComponent /></FetchUserSites></AuthGuard>}>
                {/* <Route path=':siteCode/dashboard/analysis' element={<SchedulerComponent />} /> */}
                <Route path=':siteCode/settings/sitedetails' element={<Settings />} />
                <Route path=':siteCode/settings/users' element={<ManageUsers />} />
                {/* <Route path='scheduler' element={<SchedulerComponent />} /> */}
                <Route path='settings' element={<Settings />} />
                <Route path=':siteCode/scheduler/runs' element={<FindRuns />} />
                <Route path=':siteCode/scheduler/comparerun' element={<CompareRun />} />
                <Route path=':siteCode/scheduler/rundetails/:runId' element={<RunDetails />} />
            </Route>
            <Route path='/choose-site' element={<AuthGuard><ChooseSiteComponent /></AuthGuard>} />
            <Route path='/signup' element={<SignUpComponent />} />
            <Route path='/verify-email' element={<VerifyEmailComponent />} />
            <Route path='/accept-invite' element={<AcceptInviteComponent />} />
        </Routes>
    );
};

export default RoutesConfig;