import React from "react";
import RGL, { WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import CardComponent from "../dashboard/CardComponent";
import ChartComponent from "../dashboard/ChartComponent";
import GanttChart from "../scheduler/GanttChart";
import { Flex, Card } from 'antd';
import { HolderOutlined, CloseOutlined, InfoCircleOutlined } from "@ant-design/icons";

const ReactGridLayout = WidthProvider(RGL);

function GridLayout(props) {
    const {setLayouts,setSettings,layouts,handleLayoutChange,settings,data,currentRunId} = props;

    function deleteWidget(widgetId) {
        let layouts = settings.filter(item => item.i !== widgetId)
        setLayouts(layouts);
        setSettings(layouts);
    }

    return (
        <ReactGridLayout
            className="layout"
            layout={layouts}
            onLayoutChange={handleLayoutChange}
            isDraggable
            isRearrangeable
            isResizable
            draggableHandle=".widget-drag"
            cols={12}
        >
            {settings.map(item => (
                <div key={item.i} className="grid-item">
                    <Card className="info-card card-widget" title={
                    <>
                        <Flex justify="end"><CloseOutlined onClick={e => deleteWidget(item.i)} className="close-icon" /></Flex>
                        <Flex justify="space-between" align="center">
                            <Flex><HolderOutlined size={30} color="black" style={{ color: "black", cursor: "move" }} className="widget-drag" />
                                <div className="chart-title">{item.settings.Title}</div>
                                <InfoCircleOutlined className="info-icon" />
                            </Flex>
                        </Flex>
                    </>
                    } >
                    {item.type === "card" ?
                        <CardComponent secondaryWidget={item.secondaryWidget} properties={item.settings} currentRunId={currentRunId} />
                        : item.type === 'chart' ? <ChartComponent properties={item.settings} currentRunId={currentRunId} />
                        : <GanttChart data={data} />}
                    </Card>
                </div>
            ))}
        </ReactGridLayout>
    )
}

export default GridLayout;