import React, {useState,useEffect} from "react";
import dayjs from 'dayjs';
import { DatePicker } from 'antd';
import { convertDateToUtc,convertDateToLocalTime } from "../../util";

function DateTimePicker(props) {
    const {dateValue,saveDate} = props;
    const [selectedDate, setSelectedDate] = useState(null);

    useEffect(() => {
        setSelectedDate(dayjs(convertDateToLocalTime(dateValue)))
    }, [dateValue]);

    const handleDateChange = (_,dateStr) => {
        if (dateStr) {
            const utcDate = convertDateToUtc(dateStr);
            setSelectedDate(dateStr);
            saveDate(utcDate);
        } else {
            setSelectedDate(null);
        }
    };

    return (
        selectedDate &&
        (
            <DatePicker
                className="date-time-picker"
                defaultValue={selectedDate}
                onChange={handleDateChange}
                showTime={{ format: 'HH:mm:ss' }}
                format="DD MMM 'YY HH:mm:ss"
                placeholder="Select Date and Time"
                suffixIcon={undefined}
                allowClear={false}
            />
        )
    )

}

export default DateTimePicker;